import React, { ReactNode } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';

import background from 'assets/images/react.png';

interface PublicFormLayoutProps {
  title?: ReactNode;
  children?: ReactNode;
}

const styles = {
  root: {
    height: '100vh',
    width: '100%',
  },
  container: {
    height: '100%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: (theme: Theme) => theme.spacing(10, 4, 5, 4),
  },
  imageContainer: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  image: {
    background: `url(${background})`,
    height: '100%',
    width: '100%',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
};

export const PublicFormLayout: React.FC<PublicFormLayoutProps> = ({ children, title }) => {
  return (
    <Box data-testid="public-form-layout" sx={styles.root}>
      <Grid container justifyContent="center" sx={styles.container}>
        <Grid item xs={12} sm={9} md={6} lg={4} sx={styles.formContainer}>
          <Typography align="center" variant="h4">
            {title}
          </Typography>
          {children}
        </Grid>
        <Grid item sm={3} md={6} lg={8} sx={styles.imageContainer}>
          <Box sx={styles.image} />
        </Grid>
      </Grid>
    </Box>
  );
};
