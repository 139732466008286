import { FeedbackModel } from './feedback.model';

export enum DiscourseRole {
  ASSISTANT = 'assistant',
  USER = 'user',
}

export interface DiscourseModel {
  id: string;
  parentId: string | null;
  text: string | null;
  children: string[];
  role: DiscourseRole;
  feedback: FeedbackModel | null;
  createdAt: Date;
  createdBy: string;
}

export interface DiscoursesResponse {
  discourseId: string;
  discourses: DiscourseModel[];
}

export interface DiscourseListResponse {
  data: DiscourseModel[];
  total: number;
}
