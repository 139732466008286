import React, { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box } from '@mui/material';

import { Form, Button, ControlledTextField } from 'components';
import { FeedbackModel, FeedbackExtendedModel } from 'models';
import Yup from 'utils/yup';

enum FeedbackType {
  NOT_ACCURATE = "It's actually not accurate.",
  UNSAFE = 'Offensive/unsafe',
}

const styles = {
  feedback: {
    backgroundColor: 'background.default',
    borderRadius: 2,
    mr: 2,
    ml: 2,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    '& .MuiInputBase-input': {
      '::-webkit-scrollbar': {
        width: 8,
      },

      '::-webkit-scrollbar-track': {
        backgroundColor: 'grey.100',
        borderRadius: 2,
      },

      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'grey.400',
        borderRadius: 2,
      },
    },
    backgroundColor: 'white',
    mt: 1,
  },
  modelFooterButtons: {
    mt: 1,
    display: 'flex',
    width: '100%',
    justifyContent: 'right',
  },
  title: {
    mb: 2,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
};

interface FeedbackProps {
  onClose: () => void;
  onSubmit: (values: FeedbackModel) => void;
}

const Feedback: FC<FeedbackProps> = ({ onClose, onSubmit: onSubmitFeedback }) => {
  const initialValues: FeedbackExtendedModel = { isPositive: false, text: '', notSafety: false, notCorrect: false };
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    text: Yup.string().test('custom-validation', t('ERRORS.CHOOSE_ONE'), (value, context) => {
      const { path, createError, parent } = context;
      const { notSafety, notCorrect } = parent;
      if (!value && !notSafety && !notCorrect) {
        return false;
      }
      if ((value && (notSafety || notCorrect)) || (notCorrect && notSafety)) {
        return createError({ path, message: t('ERRORS.ONLY_ONE') });
      }
      return true;
    }),
    notSafety: Yup.boolean().required(),
    notCorrect: Yup.boolean().required(),
    isPositive: Yup.boolean().required(),
  });

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const notSafetyValue = useWatch({ control, name: 'notSafety' });
  const notCorrectValue = useWatch({ control, name: 'notCorrect' });

  const onSubmit = ({ text, notCorrect, notSafety }: FeedbackExtendedModel) => {
    let texts = [text || ''];
    if (notCorrect) {
      texts = [FeedbackType.NOT_ACCURATE];
    }
    if (notSafety) {
      texts = [FeedbackType.UNSAFE];
    }

    return onSubmitFeedback({ isPositive: false, texts });
  };

  const toggleNotSafety = () => {
    setValue('notSafety', !notSafetyValue);
  };

  const toggleNotCorrect = () => {
    setValue('notCorrect', !notCorrectValue);
  };

  return (
    <Box mt={1} sx={styles.feedback} data-testid="feedback">
      <Form onSubmit={handleSubmit(onSubmit)} control={control} placement="top-right">
        <Box sx={styles.title}>
          <Trans i18nKey="CHAT.FEEDBACK_QUESTION" />
        </Box>
        <Box sx={styles.container}>
          <Box mr={1}>
            <Button onClick={toggleNotSafety} variant={notSafetyValue ? 'contained' : 'outlined'}>
              <Trans i18nKey="CHAT.UNSAFE" />
            </Button>
          </Box>
          <Box mr={1}>
            <Button onClick={toggleNotCorrect} variant={notCorrectValue ? 'contained' : 'outlined'}>
              <Trans i18nKey="CHAT.NOT_ACCURATE" />
            </Button>
          </Box>
        </Box>
        <ControlledTextField
          control={control}
          name="text"
          sx={styles.input}
          fullWidth
          multiline
          maxRows={5}
          minRows={3}
        />
        <Box sx={styles.modelFooterButtons}>
          <Box mr={2}>
            <Button variant="outlined" onClick={onClose}>
              <Trans i18nKey="COMMON.CANCEL" />
            </Button>
          </Box>
          <Button onClick={handleSubmit(onSubmit)}>
            <Trans i18nKey="COMMON.SAVE" />
          </Button>
        </Box>
      </Form>
    </Box>
  );
};

export default Feedback;
