import { PromptsModel, QueryParamModel, PromptsQueryModel } from 'models';
import request, { Methods } from 'utils/request';
import { EntityService } from 'utils/entityService';

class PromptsService extends EntityService {
  constructor() {
    super('prompts');
  }

  async editPrompts(data: PromptsModel) {
    return request<PromptsModel>({
      method: Methods.PUT,
      resource: `${this.resource}/${data.key}`,
      data,
    });
  }

  async editRestore(key: string) {
    return request<PromptsModel>({
      method: Methods.PATCH,
      resource: `${this.resource}/restore/${key}`,
    });
  }

  async getPrompts(params?: QueryParamModel | null) {
    return request<PromptsQueryModel>({
      resource: `${this.resource}`,
      params,
    });
  }
}

export const promptsService = new PromptsService();
