import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ReactComponent as HuFlag } from 'flag-icons/flags/4x3/hu.svg';
import { ReactComponent as EnFlag } from 'flag-icons/flags/4x3/gb.svg';

export const languages = [
  {
    flag: HuFlag,
    code: 'hu',
    label: 'Magyar',
  },
  {
    flag: EnFlag,
    code: 'en',
    label: 'English',
  },
];

const styles = {
  formControl: {
    margin: 1,
    '& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiSelect-select': {
      minWidth: 'unset',
      paddingRight: 0,
      display: 'flex',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectEmpty: {
    marginTop: 2,
  },
  langText: {
    marginLeft: 1,
  },
  flag: {
    width: 20,
  },
};

export const LanguageSelect: FC = () => {
  const { i18n } = useTranslation();
  const [value, setValue] = React.useState(i18n.language);

  const handleChange = async (event: SelectChangeEvent<string>) => {
    const lang = event.target.value;
    await i18n.changeLanguage(lang === 'gb' ? 'en' : lang);
    setValue(lang);
  };

  return (
    <Box display="flex" flexWrap="wrap">
      <FormControl sx={styles.formControl}>
        <Select variant="standard" value={(value || '').toLowerCase().substring(0, 2)} onChange={handleChange}>
          {languages.map((language) => (
            <MenuItem value={language.code} key={language.code}>
              <Box display="flex" alignItems="center" sx={styles.flag}>
                <language.flag />
              </Box>

              <Box display="flex" alignItems="center" sx={styles.langText}>
                {language.label}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
