import React, { useState } from 'react';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import { Role } from 'config/roles';
import { Button, Dialog, DialogProps, Form, showNotification, FileUploadField, ControlledTextField } from 'components';
import { KnowledgeFieldType, KnowledgeUploadModel, NotificationType } from 'models';
import { settings } from 'config/settings';

const styles = {
  buttonContainer: {
    width: '100%',
    display: 'flex',
  },
  button: {
    marginLeft: 'auto',
  },
};

export interface KnowledgeModalProps extends DialogProps {
  create: (values: KnowledgeUploadModel) => Promise<void>;
}

export function KnowledgeModal({ create, onClose, ...props }: KnowledgeModalProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const initialValues: KnowledgeUploadModel = {
    url: '',
    file: null,
    accessGroup: '',
    fieldType: KnowledgeFieldType.URL,
  };

  const validationSchema = Yup.object().shape({
    accessGroup: Yup.string().required(),
    file: Yup.array()
      .nullable()
      .when('fieldType', {
        is: (val: KnowledgeFieldType) => val === KnowledgeFieldType.FILE,
        then: (schema) => schema.required(),
      }),
    url: Yup.string()
      .nullable()
      .when('fieldType', {
        is: (val: KnowledgeFieldType) => val === 'URL',
        then: (schema) => schema.matches(settings.URL_REGEX, t('ERRORS.NOT_VALID_URL')).required(),
      }),
    fieldType: Yup.mixed<KnowledgeFieldType>().required(),
  });

  const { handleSubmit, control } = useForm({
    values: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const fieldType: KnowledgeFieldType = useWatch({ control, name: 'fieldType' });

  const fieldTypes = {
    [KnowledgeFieldType.URL]: <ControlledTextField control={control} fullWidth name="url" label={t('KNOWLEDGE.URL')} />,
    [KnowledgeFieldType.FILE]: <FileUploadField control={control} name="file" />,
  };

  const onSubmit: SubmitHandler<KnowledgeUploadModel> = async (values: KnowledgeUploadModel) => {
    setLoading(true);
    try {
      await create(values);
      onClose();
      showNotification({ content: t('COMMON.OPERATION_SUCCESSFUL'), type: NotificationType.SUCCESS });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog title={<Trans i18nKey="KNOWLEDGE.CREATE" />} onClose={onClose} {...props}>
      <Form onSubmit={handleSubmit(onSubmit)} control={control} placement="top-left">
        <Grid container>
          <Grid item xs={12}>
            <ControlledTextField control={control} name="accessGroup" label={t('KNOWLEDGE.ACCESS_GROUP')} select>
              {Object.values(Role).map((value) => (
                <MenuItem value={value} key={value}>
                  <Trans i18nKey={`ACCESS_GROUP.${value}`} />
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField control={control} name="fieldType" label={t('KNOWLEDGE.TYPE')} select fullWidth>
              {(Object.keys(KnowledgeFieldType) as Array<keyof typeof KnowledgeFieldType>).map((key) => (
                <MenuItem value={key} key={key}>
                  {t(`KNOWLEDGE.${key}`)}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12}>
            {fieldTypes[fieldType]}
          </Grid>
        </Grid>
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.button} onClick={handleSubmit(onSubmit)} loading={loading}>
            <Trans i18nKey="COMMON.SAVE" />
          </Button>
        </Box>
      </Form>
    </Dialog>
  );
}
