import React, { FC, ImgHTMLAttributes, useCallback, useState } from 'react';
import Box from '@mui/material/Box';

import { mergeSx } from 'utils/styles';

import noImage from 'assets/images/no-image.png';
import { SxProp } from 'models';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      maxWidth: '100%',
    },
  },
};

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement>, SxProp {}

export const Image: FC<ImageProps> = ({ src, className, alt, sx, ...props }) => {
  const [hasError, setHasError] = useState(false);

  const onError = useCallback(() => {
    setHasError(true);
  }, [setHasError]);

  return (
    <Box sx={mergeSx(styles.root, sx)}>
      <img
        src={hasError || !src ? noImage : src}
        onError={onError}
        className={className}
        alt={alt || src || 'No image'}
        data-testid="image"
        {...props}
      />
    </Box>
  );
};
