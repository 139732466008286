import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SlideProps } from '@mui/material/Slide';

import { Theme } from '@mui/material/styles';

import { SxProp } from 'models';
import { usePrevious } from 'utils/usePrevious';
import { mergeSx } from 'utils/styles';

import { PanelContent } from './PanelContent';

const styles = {
  drawer: (theme: Theme) => ({
    ...theme.mixins.panel,
    '& .MuiDrawer-paper': {
      ...theme.mixins.panel,
      padding: 0,
      overflow: 'hidden',
      maxWidth: '100%',
    },
  }),
  hidden: {
    display: 'none',
  },
};

export type PanelVariant = 'left' | 'right';

export interface PanelProps extends SxProp {
  /**
   * Determines if the panel is open or not.
   */
  isOpen?: boolean | null;
  /**
   * Function that triggers on the panels close event.
   */
  onClose?: () => void;
  /**
   * The class(es) (in case if you prefer classes over sx).
   */
  className?: string;
  /**
   * Callback fired after the "entered" status is applied. An extra parameter ```isAppearing``` is supplied to indicate if the enter stage is occurring on the initial mount.
   */
  onEntered?: SlideProps['onEntered'];
  /**
   * Where the panel shows up.
   */
  variant?: PanelVariant;
  /**
   * The content of the panel.
   */
  children?: ReactNode;
}

export const Panel: FC<PanelProps> = ({ children, isOpen, onClose, sx, className, onEntered, variant = 'right' }) => {
  const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up(theme.breakpoints.values.md));
  const smallScreen = !largeScreen;
  const prevOpen = usePrevious(isOpen);

  const slideProps = useMemo(
    () => ({
      onEntered,
    }),
    [onEntered]
  );

  const modalProps = useMemo(
    () => ({
      disableScrollLock: true,
    }),
    []
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (smallScreen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [smallScreen, isOpen]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    if (prevOpen && !isOpen) {
      document.body.style.overflow = '';
    }
  }, [isOpen, prevOpen]);

  // We need to duplicate the variants and hide the inactive one, because changing the variant causes a rerender,
  // and a rerender is problematic for dialogs inside the panel.
  return (
    <>
      <Drawer
        data-testid="small-screen-panel"
        className={className}
        sx={mergeSx(styles.drawer, smallScreen ? styles.hidden : null, sx)}
        variant="persistent"
        anchor={variant}
        open={Boolean(isOpen)}
        onClose={onClose}
        SlideProps={slideProps}
      >
        <PanelContent>{children}</PanelContent>
      </Drawer>
      <Drawer
        data-testid="large-screen-panel"
        className={className}
        sx={mergeSx(styles.drawer, largeScreen ? styles.hidden : null, sx)}
        variant="temporary"
        anchor={variant}
        open={Boolean(isOpen)}
        onClose={onClose}
        SlideProps={slideProps}
        ModalProps={modalProps}
      >
        <PanelContent>{children}</PanelContent>
      </Drawer>
    </>
  );
};
