import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { selectApiInfoState } from 'store/common';
import { Env } from 'config/env';

const styles = {
  version: {
    color: 'dark.contrastText',
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
};

export const VersionsDisplay: FC = () => {
  const apiInfo = useSelector(selectApiInfoState);

  return (
    <Box mt="auto" mb={1} mx={1} display="flex" sx={styles.version}>
      <Typography color="inherit" display="inline">
        <Trans i18nKey="COMMON.VERSION" />: {Env.REACT_APP_VERSION}
      </Typography>
      <Typography data-testid="version-display-api-version" color="inherit" display="inline">
        <Trans i18nKey="COMMON.API_VERSION" />: {apiInfo.version}
      </Typography>
    </Box>
  );
};
