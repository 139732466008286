import React, { FC, ReactNode, useState } from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import Dropzone, { DropEvent, DropzoneProps, FileRejection } from 'react-dropzone';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import { alpha, Theme } from '@mui/material/styles';
import UploadIcon from '@mui/icons-material/CloudUpload';

import i18n from 'i18n';

const styles = {
  root: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px dashed ${alpha(theme.palette.secondary.main, 0.2)}`,
    transition: theme.transitions.create(['border', 'background-color'], {
      duration: theme.transitions.duration.complex,
    }),
    height: theme.spacing(16),
    width: '100%',
    cursor: 'pointer',
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
    },
    '&:hover, &.active, &.loading, &.loading': {
      borderColor: theme.palette.secondary.main,

      '& .uploadIcon': {
        '@keyframes float': {
          '0%': {
            transform: 'translateY(0px)',
          },
          '50%': {
            transform: 'translateY(-4px)',
          },
          '100%': {
            transform: 'translateY(0px)',
          },
        },
        animationName: 'float',
        animationDuration: '1s',
        animationTimingFunction: 'ease-out',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
      },
    },
    '&.active.accept': {
      backgroundColor: 'red',
      '& .drop': {
        display: 'block',
      },
    },
    '&.active.reject': {
      borderColor: theme.palette.error.main,
      '& .reject': {
        display: 'block',
      },
    },
  }),
  label: {
    marginBottom: 1,
  },
};

enum FileUploadError {
  UNSUPPORTED_FILE_TYPE = 'file-invalid-type',
  FILE_SIZE_TOO_LARGE = 'file-too-large',
  FILE_SIZE_TOO_SMALL = 'file-too-small',
  DEFAULT_ERROR = 'error',
}

export const FileErrorLabelMap: FileErrorLabelType = {
  [FileUploadError.UNSUPPORTED_FILE_TYPE]: () => i18n.t('ERRORS.UNSUPPORTED'),
  [FileUploadError.FILE_SIZE_TOO_LARGE]: () => i18n.t('ERRORS.FILE_SIZE_TOO_LARGE'),
  [FileUploadError.FILE_SIZE_TOO_SMALL]: () => i18n.t('ERRORS.FILE_SIZE_TOO_SMALL'),
  [FileUploadError.DEFAULT_ERROR]: () => i18n.t('ERRORS.DEFAULT_FILE_ERROR'),
};

type FileErrorLabelType = {
  [key in FileUploadError]: () => string;
};

export interface FileUploadProps extends DropzoneProps {
  loading?: boolean;
  label?: ReactNode;
  errorNode?: ReactNode;
  valueDisplayNode?: ReactNode;
}

export const FileUpload: FC<FileUploadProps> = ({
  multiple = false,
  onDropRejected,
  onDropAccepted,
  loading = false,
  label,
  valueDisplayNode,
  errorNode,
  ...props
}) => {
  const [error, setError] = useState<FileUploadError | null>(null);

  const onRejected = (files: FileRejection[], event: DropEvent) => {
    setError(
      FileUploadError[
        (Object.keys(FileUploadError).find(
          (key) => FileUploadError[key as keyof typeof FileUploadError] === files[0]?.errors[0]?.code
        ) || FileUploadError.DEFAULT_ERROR) as keyof typeof FileUploadError
      ]
    );

    /* istanbul ignore else */
    if (onDropRejected) {
      onDropRejected(files, event);
    }
  };

  const onAccepted = (files: File[], event: DropEvent) => {
    setError(null);

    /* istanbul ignore else */
    if (onDropAccepted) {
      onDropAccepted(files, event);
    }
  };

  return (
    <div>
      {!!label && (
        <InputLabel sx={styles.label} shrink>
          {label}
        </InputLabel>
      )}
      <Dropzone multiple={multiple} onDropRejected={onRejected} onDropAccepted={onAccepted} {...props}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <Box
              {...getRootProps()}
              sx={styles.root}
              className={classNames({
                active: isDragActive,
                loading,
              })}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <div>
                  <input data-testid="drop-input" {...getInputProps()} />
                  <Typography>
                    <Trans i18nKey="COMMON.DROP_FILES_HERE" />
                  </Typography>

                  <UploadIcon fontSize="large" color="secondary" className="uploadIcon" />
                </div>
              )}
            </Box>
          );
        }}
      </Dropzone>
      {errorNode}
      <FormHelperText error>{error && FileErrorLabelMap[error] ? FileErrorLabelMap[error]() : null}</FormHelperText>
      {valueDisplayNode}
    </div>
  );
};
