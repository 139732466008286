import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ChatState {
  lastAnswerId: string | null;
  isLoading: boolean;
  title: string;
}

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    lastAnswerId: null,
    isLoading: false,
    title: '',
  } as ChatState,
  reducers: {
    setLastAnswerId: (state, action: PayloadAction<string>) => {
      state.lastAnswerId = action.payload;
    },

    setIsLoadingOn: (state) => {
      state.isLoading = true;
    },

    setIsLoadingOff: (state) => {
      state.isLoading = false;
    },

    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },

    setSSETitle: (state, action: PayloadAction<string>) => {
      state.title += action.payload;
    },
  },
});

const { actions } = chatSlice;

export const chatReducer = chatSlice.reducer;

export const { setLastAnswerId, setIsLoadingOff, setIsLoadingOn, setTitle, setSSETitle } = actions;

export const selectLastAnswerId = (state: ApplicationState) => {
  return state.chat.lastAnswerId;
};

export const selectIsLoading = (state: ApplicationState) => {
  return state.chat.isLoading;
};

export const selectTitle = (state: ApplicationState) => {
  return state.chat.title;
};
