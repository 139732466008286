import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel, KnowledgeModel } from 'models';
import { knowledgesService } from 'services';

type UserQueryContexts = QueryContextFromKeys<typeof keys>;

const keys = {
  all: [{ scope: 'knowledges' }] as const,
  lists: () => [{ ...keys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...keys.lists()[0], params }] as const,
};

const fetchKnowledgesList = async ({ queryKey: [{ params }] }: UserQueryContexts['list']) =>
  knowledgesService.getKnowledges(params);

export const useKnowledgesList = (params?: QueryParamModel | null) => useQuery(keys.list(params), fetchKnowledgesList);

export const useDeleteKnowledge = () => {
  const queryClient = useQueryClient();

  return useMutation((id: string) => knowledgesService.deleteKnowledge(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.lists() });
    },
  });
};

export const useDeleteKnowledges = () => {
  const queryClient = useQueryClient();

  return useMutation(() => knowledgesService.deleteKnowledges(), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.lists() });
    },
  });
};

export const useEditKnowledge = () => {
  const queryClient = useQueryClient();

  return useMutation((data: Partial<KnowledgeModel>) => knowledgesService.editKnowledge(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.lists() });
    },
  });
};

export const useCreateKnowledge = () => {
  const queryClient = useQueryClient();

  return useMutation((data: FormData) => knowledgesService.createKnowledge(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.lists() });
    },
  });
};
