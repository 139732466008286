import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SnackBar, { SnackbarProps } from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import Link from '@mui/material/Link';
import { Theme } from '@mui/material/styles';

import { SnackbarContent, Button, LinkButton } from 'components';
import { setCookieConsent, selectCookieConsent } from 'store/auth';
import { showLoader, hideLoader } from 'store/common';
import { useAppDispatch } from 'utils/useAppDispatch';
import { NotificationType } from 'models';
import { authService } from 'services';

interface CookieConsentProps extends SnackbarProps {
  /**
   * If set to ```true``` a backdrop appears, so the user is going to be unable to interact with the rest of the site.,
   */
  backdrop?: boolean;
}

const styles = {
  backdrop: {
    zIndex: 1299,
  },
  snackBar: {
    width: (theme: Theme) => `calc(100% - ${theme.spacing(6)})`,
  },
  snackbarContent: {
    width: '100%',
    borderRadius: 0,
  },
};

export const CookieConsent: React.FC<CookieConsentProps> = ({ backdrop }) => {
  const dispatch = useAppDispatch();

  const cookieConsent = useSelector(selectCookieConsent);

  const [open, setOpen] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState<string | null>(null);
  const [terms, setTerms] = useState<string | null>(null);

  const load = useCallback(async () => {
    dispatch(showLoader());
    try {
      if (cookieConsent) {
        if (backdrop) {
          document.body.style.overflow = 'visible';
        }
      } else {
        setOpen(true);

        if (backdrop) {
          document.body.style.overflow = 'hidden';
        }

        const consentUrls = await authService.getConsentUrls();

        setPrivacyPolicy(consentUrls?.privacyPolicy || null);
        setTerms(consentUrls?.termsAndConditions || null);
      }
    } finally {
      dispatch(hideLoader());
    }
  }, [backdrop, cookieConsent, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const onAgree = () => {
    document.body.style.overflow = 'visible';
    dispatch(setCookieConsent('agreed'));
    setOpen(false);
  };

  const snackBar = (
    <SnackBar data-testid="cookie-consent" sx={styles.snackBar} open={open}>
      <SnackbarContent
        sx={styles.snackbarContent}
        hideCloseButton
        action={
          <Box display="flex">
            <Box mr={2}>
              <Button customTestId="cookie-consent-agree" onClick={onAgree}>
                <Trans i18nKey="COMMON.AGREE" />
              </Button>
            </Box>
            <Box>
              <LinkButton target="_blank" href={privacyPolicy || ''} color="info">
                <Trans i18nKey="COMMON.PRIVACY_POLICY" />
              </LinkButton>
            </Box>
          </Box>
        }
        message={
          <Box ml={2}>
            <Typography>
              <Trans i18nKey="CONSENT.COOKIE_HEADER" />
            </Typography>
            <Typography variant="caption">
              <Trans
                i18nKey="CONSENT.COOKIE_CONTENT"
                components={[
                  <Link target="_blank" color="secondary" key="terms-link" href={terms || ''} underline="hover" />,
                ]}
              />
            </Typography>
          </Box>
        }
        variant={NotificationType.INFO}
      />
    </SnackBar>
  );

  return backdrop ? (
    <Backdrop sx={styles.backdrop} open={open}>
      {snackBar}
    </Backdrop>
  ) : (
    snackBar
  );
};
