import React from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { PLACEMENT } from '@hookform/devtools';
import { DevTool } from 'components/util/Devtool';

interface FormProps<T extends FieldValues> {
  onSubmit: () => void;
  children: React.ReactNode;
  placement: PLACEMENT;
  control: Control<T, any> | undefined;
}

export function Form<T extends FieldValues>({ onSubmit, children, placement, control }: FormProps<T>) {
  return (
    <form onSubmit={onSubmit}>
      <DevTool placement={placement} control={control} />
      {children}
    </form>
  );
}
