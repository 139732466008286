import React, { FC, useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Container, Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { authService } from 'services/auth.service';
import { LanguageSelect, TextField, Form, Button } from 'components';

import background from 'assets/images/login-image.png';
import logo from 'assets/images/attrecto-colored.png';
import { LoginModel } from 'models';
import { userService } from 'services';
import { getProfile } from 'store/profile';
import { useAppDispatch } from 'utils/useAppDispatch';

const styles = {
  backgroundContainer: {
    background: (theme: Theme) => theme.palette.common.white,
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: -1,
  },
  backgroundImage: {
    right: -250,
    top: -250,
    position: 'absolute' as const,
  },
  logo: {
    left: 16,
    top: 16,
  },
  loginForm: {
    paddingTop: '50%',
    textAlign: 'center' as const,
  },
  languageSelect: {
    bottom: 16,
    left: 16,
  },
  button: {
    backgroundColor: 'social.google.main',
    color: 'social.google.contrastText',
    minWidth: 400,
    '&:hover': {
      backgroundColor: 'social.google.dark',
    },
    textTransform: 'none',
  },

  googleLogo: {
    mr: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginText: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
};

const initialValues: LoginModel = {
  name: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  password: Yup.string().required(),
});

const LoginPage: FC = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onTouched',
  });

  const initProfile = useCallback(async () => {
    await dispatch(getProfile());
  }, [dispatch]);

  const onSubmit = async (credentials: LoginModel) => {
    setLoading(true);
    try {
      await authService.login(credentials);
      await userService.getProfile();
      initProfile();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box position="fixed" sx={styles.backgroundContainer}>
        <Box sx={styles.backgroundImage}>
          <img src={background} alt="login-background" />
        </Box>
      </Box>
      <Box position="fixed" sx={styles.logo}>
        <img src={logo} alt="logo" />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={styles.loginForm}>
        <Box mb={3}>
          <Typography variant="h3">
            <Trans i18nKey="COMMON.SIGN_IN" />
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography color="textSecondary">
            <Trans i18nKey="COMMON.SIGN_IN_INFO" />
          </Typography>
        </Box>
        <Form onSubmit={handleSubmit(onSubmit)} control={control} placement="top-left">
          <TextField
            {...register('name')}
            label={<Trans i18nKey="COMMON.NAME" />}
            errorMessage={errors?.name?.message}
            fullWidth
          />
          <TextField
            {...register('password')}
            errorMessage={errors?.password?.message}
            type="password"
            label={<Trans i18nKey="USER.PASSWORD" />}
            fullWidth
          />
          <Button customTestId="confirm-dialog-confirm" onClick={handleSubmit(onSubmit)} loading={loading}>
            <Trans i18nKey="LOGIN.TITLE" />
          </Button>
        </Form>
      </Box>
      <Box position="fixed" sx={styles.languageSelect}>
        <LanguageSelect />
      </Box>
    </Container>
  );
};

export default LoginPage;
