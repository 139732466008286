import React, { FC, ReactNode, useCallback, SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { toggleSidebar, toggleMobileSidebar, selectSideBarState } from 'store/common';
import { logout } from 'store/auth';
import { selectProfile } from 'store/profile';
import { useAppDispatch } from 'utils/useAppDispatch';
import { LanguageSelect } from 'components';

import { Button, Menu, MenuItem } from '@mui/material';
import { ModulesSelect, UserAvatar } from '@attrecto/apps-react-components';

const styles = {
  appBar: {
    backgroundColor: 'background.paper',
    color: 'text.primary',
    '& .MuiToolbar-root': {
      transition: (theme: Theme) =>
        theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
  },
  title: {
    display: {
      xs: 'none',
      md: 'block',
    },
  },
  moduleSelect: {
    mr: '10px',
    mb: '1px',
  },
  menuTitle: {
    display: {
      xs: 'none',
      md: 'block',
    },
  },
  menu: {
    textTransform: 'none',
    minWidth: {
      xs: 'auto',
      md: '64px',
    },
  },
  appBarShift: {
    backgroundColor: 'background.paper',
    color: 'text.primary',
    '& .MuiToolbar-root': {
      ml: (theme: Theme) => ({
        md: `${theme.mixins.drawer.width}px`,
      }),

      transition: (theme: Theme) =>
        theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },
  },
  avatar: {
    backgroundColor: 'dark.main',
    color: 'dark.contrastText',
  },
  menuButton: {
    mr: 1,
  },
  hide: {
    display: {
      md: 'none',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  arrowBox: {
    '& .MuiBox-root': {
      display: 'inline-flex',
      alignItems: 'center',
      color: 'text.secondary',
    },
  },
  titleContainerOpenSidebar: {
    width: (theme: Theme) => `calc(90vw - ${theme.spacing(55)} - ${theme.mixins.drawer.width}px)`,
    transition: (theme: Theme) =>
      theme.transitions.create(['width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
  },
  titleContainerCloseSidebar: {
    width: (theme: Theme) => `calc(90vw - ${theme.spacing(55)})`,
    transition: (theme: Theme) =>
      theme.transitions.create(['width'], {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen,
      }),
  },
};

export interface HeaderProps {
  title: ReactNode;
}

export const Header: FC<HeaderProps> = ({ title }) => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const profile = useSelector(selectProfile);
  const sideBar = useSelector(selectSideBarState);

  const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up(theme.breakpoints.values.md));

  const onMenuIconClick = useCallback(() => {
    return largeScreen ? dispatch(toggleSidebar()) : dispatch(toggleMobileSidebar());
  }, [largeScreen, dispatch]);

  const onLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  function handleOpen(event: SyntheticEvent<any>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <AppBar elevation={1} position="fixed" sx={sideBar.open ? styles.appBarShift : styles.appBar}>
        <Toolbar sx={styles.toolbar}>
          <Box
            display="flex"
            alignItems="center"
            sx={sideBar.open ? styles.titleContainerOpenSidebar : styles.titleContainerCloseSidebar}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={onMenuIconClick}
              sx={sideBar.open ? styles.hide : styles.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap sx={styles.title}>
              {title}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box sx={styles.moduleSelect}>
              <ModulesSelect modules={profile?.availableModules} noModuleTitle={<Trans i18nKey="COMMON.NO_MODULE" />} />
            </Box>
            <Box>
              <LanguageSelect />
            </Box>
            <Box mr={1}>
              <Button sx={styles.menu} onClick={handleOpen}>
                <Typography color="textSecondary" sx={styles.menuTitle}>
                  {profile?.name}
                </Typography>
                <Box
                  sx={styles.arrowBox}
                  display="inline-flex"
                  alignItems="center"
                  color="text.secondary"
                  ml={{ md: 2 }}
                >
                  <ExpandMoreIcon fontSize={'small'} />
                </Box>
              </Button>
            </Box>
            <UserAvatar userName={profile?.name || undefined} avatarUrl={profile?.picture || undefined} />
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={onLogout}>
                <Typography>
                  <Trans i18nKey="COMMON.LOGOUT" />
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};
