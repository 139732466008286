import React, { FC, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box } from '@mui/material';

import { DialogProps, Autocomplete, Dialog, Button, Form } from 'components';
import { ConversationModel, PersonModel } from 'models';
import { conversationService } from 'services';
import Yup from 'utils/yup';
import { useEditConversation } from 'features/conversations';
import { useUsersList } from 'features/users';
import { selectProfile } from 'store/profile';

interface ValueModel {
  shared: PersonModel[];
}

export interface ShareDialogProps extends DialogProps {
  selected: ConversationModel | null;
}

const ShareDialog: FC<ShareDialogProps> = ({ onClose, selected, ...props }) => {
  const profile = useSelector(selectProfile);

  const [conversation, setConversation] = useState<ConversationModel | null>();
  const [loading, setLoading] = useState(false);
  const { mutateAsync: edit } = useEditConversation();

  const { data: users } = useUsersList();
  const options = useMemo(() => users?.filter((user) => user.id !== profile?.id), [users, profile]);

  const validationSchema = Yup.object().shape({
    shared: Yup.array().required(),
  });

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<ValueModel> = async (value: ValueModel) => {
    setLoading(true);
    if (conversation) {
      try {
        await edit({ ...conversation, sharedWithUserIds: value.shared.map((user) => user.id) });
        onClose();
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const initConversation = async (id: string) => {
      const res = await conversationService.getConversation(id);
      setConversation(res);
      reset({
        shared:
          (res?.sharedWithUserIds
            ?.map((optionId) => users?.find((option) => option.id === optionId))
            .filter(Boolean) as PersonModel[]) || [],
      });
    };
    if (selected?.id) {
      initConversation(selected.id);
    }
  }, [reset, selected?.id, users]);

  return (
    <Dialog
      title={<Trans i18nKey="CHAT.SHARE" />}
      onClose={onClose}
      actions={
        <Button customTestId="confirm-dialog-confirm" onClick={handleSubmit(onSubmit)} loading={loading}>
          <Trans i18nKey="COMMON.SAVE" />
        </Button>
      }
      {...props}
    >
      <Box mt={1}>
        <Form onSubmit={handleSubmit(onSubmit)} control={control} placement="top-left">
          <Autocomplete
            control={control}
            name="shared"
            multiple
            variant="outlined"
            fullWidth
            displayKey="name"
            valueKey="id"
            disableClearable
            label={<Trans i18nKey="CHAT.SHARE_WITH" />}
            options={options || []}
          />
        </Form>
      </Box>
    </Dialog>
  );
};

export default ShareDialog;
