import get from 'lodash/get';
import { Env } from 'config/env';

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

function filter<P = any>(object: P, key: keyof P, term?: string) {
  const value = get(object, key) || '';

  return value.toLowerCase().indexOf((term || '').toString().toLowerCase()) > -1;
}

export function arrayFilter<P = any>(collection: P[], filterKeys: Array<keyof P>, term?: string) {
  return collection.filter((item) =>
    filterKeys.reduce<boolean>((result, value) => result || filter(item, value, term), false)
  );
}

export function getArraySortComparer<P = any>(arr: P[], prop: keyof P, direction?: SortDirection) {
  const isAscending = !direction || direction === SortDirection.ASC;
  const sortModifier = isAscending ? 1 : -1;

  if (arr.length && get(arr[0], prop) && get(arr[0], prop).localeCompare) {
    return (a: P, b: P) => get(a, prop).localeCompare(get(b, prop)) * sortModifier;
  }

  return (a: P, b: P) => {
    if (a[prop] === b[prop]) {
      return 0;
    }

    return a[prop] > b[prop] ? 1 * sortModifier : -1 * sortModifier;
  };
}

export function arraySort<P extends Record<string, any> = any>(arr: P[], prop: keyof P, direction?: SortDirection) {
  const comparerFunction = getArraySortComparer(arr, prop, direction);

  return [...arr].sort(comparerFunction);
}

export function getPage<P = any>(arr: P[], page = 1, pageSize = Env.ROWS_PER_PAGE_OPTIONS[0]) {
  return arr.slice((page - 1) * pageSize, page * pageSize);
}

export function removeDuplicates<P extends Record<string, any> = any>(array: P[], key: keyof P) {
  const lookup = new Set();
  return array.filter((obj) => !lookup.has(obj[key]) && lookup.add(obj[key]));
}

export function getDifferenceBy<P extends Record<string, any> = any>(array1: P[], array2: P[], key: keyof P) {
  return array1.filter((a1) => !array2.find((a2) => a1[key] === a2[key]));
}

export function arrayify<T>(item?: T | T[] | null | undefined) {
  if (Array.isArray(item)) {
    return item;
  }

  if (item) {
    return [item];
  }

  return [];
}

export function removeEmpty<T>(items?: T[] | null) {
  const array = arrayify(items);

  return array.filter((item) => item) as NonNullable<T>[];
}

export const removeOneItemFromArray = <T>(arr: Array<T>, selected: T, key: keyof T) =>
  arr.filter((item) => item[key] !== selected[key]);
