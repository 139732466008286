import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { Env } from 'config/env';
import { CookieConsent } from 'components';
import { selectIsLoggedIn } from 'store/auth';

interface PrivateRouteProps {
  children: ReactNode;
}

export function PrivateRoute({ children }: PrivateRouteProps) {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return isLoggedIn ? (
    <>
      {children}
      {Env.COOKIE_CONSENT_USED && <CookieConsent backdrop />}
    </>
  ) : null;
}
