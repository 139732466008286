import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { removeCookie } from 'typescript-cookie';

import { clearProfile } from 'store/profile';

export interface AuthState {
  cookieConsent: boolean;
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    error: null,
    loading: false,
    cookieConsent: localStorage.getItem('cookieConsent') === 'agreed',
  } as AuthState,
  reducers: {
    setCookieConsent: (state, action: PayloadAction<string>) => {
      state.cookieConsent = true;
      localStorage.setItem('cookieConsent', action.payload);
    },
  },
});

export const authReducer = authSlice.reducer;
export const { setCookieConsent } = authSlice.actions;

export const logout = () => async (dispatch: AppDispatch) => {
  try {
    removeCookie('accessToken');
    removeCookie('refreshToken');
  } finally {
    dispatch(clearProfile());
  }
};

export const selectCookieConsent = (state: ApplicationState) => {
  return state.auth.cookieConsent;
};
export const selectIsLoggedIn = (state: ApplicationState) => {
  return Boolean(state.profile.profile);
};
