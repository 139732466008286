import React, { FC, ReactNode } from 'react';

import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import Box from '@mui/material/Box';

import { useTestId } from 'components';
import { Env } from 'config/env';
import { mergeSx } from 'utils/styles';
import { ErrorMessage } from 'components/util/ErrorMessage';

const styles = {
  fullWidth: {
    width: '100%',
  },
};

export interface TextFieldProps {
  /**
   * If set to ```true```, an error message is going to be visible under the field, in case of an error.
   */
  showError?: boolean;
  /**
   * It prevents the user from changing the value of the field (not from interacting with the field).
   */
  readOnly?: boolean;
  /**
   * The error message to display.
   */
  errorMessage?: string;
  /**
   * The label content.
   */
  label?: ReactNode;
  /**
   * The variant to use.
   */
  variant?: MuiTextFieldProps['variant'];
  /**
   * For testing purposes, you can set a testid for the text field.
   */
  customTestId?: string;

  showEmpty?: boolean;
}

export const TextField: FC<TextFieldProps & MuiTextFieldProps> = React.forwardRef(
  (
    { variant, errorMessage, showError, readOnly, margin, autoComplete, customTestId, type, name, showEmpty, ...props },
    ref
  ) => {
    const { testIdObject } = useTestId({ name, testId: customTestId, componentName: 'TextField' });
    return (
      <Box sx={mergeSx(props.fullWidth ? styles.fullWidth : null)}>
        <MuiTextField
          inputRef={ref}
          name={name}
          {...props}
          type={autoComplete === 'off' ? 'search' : type}
          margin={margin}
          variant={variant}
          error={Boolean(errorMessage?.length)}
          autoComplete={autoComplete}
          InputProps={{
            ...(props.InputProps || {}),
            readOnly: (props.InputProps || {}).readOnly || readOnly || false,
            ...testIdObject,
          }}
        />
        <ErrorMessage variant={variant} show={showError} message={errorMessage} showEmpty={showEmpty} />
      </Box>
    );
  }
);

TextField.defaultProps = {
  showError: true,
  fullWidth: true,
  variant: Env.DEFAULT_INPUT_VARIANT,
  margin: Env.DEFAULT_INPUT_MARGIN,
};
