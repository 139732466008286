import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ProfileImage, showNotification } from 'components';
import { DiscourseModel, FeedbackModel, NotificationType } from 'models';
import { selectIsLoading, setLastAnswerId } from 'store/chat';
import { useAppDispatch } from 'utils/useAppDispatch';
import Feedback from './Feedback';
import MarkDown from './MarkDown';

const NO_IMAGE = 'AA';
const DEFAULT_TEXT = '...';

const styles = {
  text: {
    ml: 2,
    width: '100%',
    '& pre': {
      margin: 0,
      textWrap: 'wrap',
      overflowWrap: 'break-word',
    },
  },
  answer: {
    backgroundColor: 'background.default',
    borderRadius: 2,
    mr: 2,
    ml: 2,
    pt: 2,
    pl: 2,
    pr: 2,
    pb: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  answerBottom: {
    display: 'flex',
  },
  input: {
    '& .MuiInputBase-input': {
      '::-webkit-scrollbar': {
        width: 8,
      },

      '::-webkit-scrollbar-track': {
        backgroundColor: 'grey.100',
        borderRadius: 2,
      },

      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'grey.400',
        borderRadius: 2,
      },
    },
  },
  navigator: {
    ml: 'auto',
    display: 'flex',
  },
  feedback: {
    display: 'flex',
    alignItems: 'center',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
  },
};

interface AnswerProps {
  message: DiscourseModel;
  next: () => void;
  previous: () => void;
  active?: number;
  length?: number;
  regenerate: () => void;
  submitFeedback: (discourseId: string, feedback: FeedbackModel) => void;
  disabled?: boolean;
}

const Answer: FC<AnswerProps> = ({ message, next, previous, active, length, regenerate, submitFeedback, disabled }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectIsLoading);
  const [like, setLike] = useState<boolean | null>(message.feedback?.isPositive || null);
  const [disLike, setDisLike] = useState<boolean | null>(message.feedback?.isPositive || null);
  const [showFeedback, setShowFeedback] = useState<boolean>();

  const showPaginator = useMemo(() => active !== undefined && length && length > 1, [active, length]);
  const activePageNumber = useMemo(() => active !== undefined && active + 1, [active]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(message?.text || '');
      showNotification({ content: t('CHAT.COPY'), type: NotificationType.SUCCESS });
    } catch (error) {
      showNotification({ content: t('CHAT.NOT_COPY'), type: NotificationType.ERROR });
    }
  };

  const handleLike = useCallback(() => {
    if (!disLike && !like) {
      submitFeedback(message.id, { isPositive: true, texts: null });
      setLike(true);
    }
  }, [disLike, like, message.id, submitFeedback]);

  const handleDisLike = useCallback(() => {
    if (!like && !disLike) {
      setDisLike(true);
      setShowFeedback(true);
    }
    if (disLike && showFeedback) {
      setShowFeedback(false);
      setDisLike(false);
    }
  }, [disLike, like, showFeedback]);

  const onCloseFeedback = useCallback(() => {
    setShowFeedback(false);
    setDisLike(false);
  }, []);

  const handleSubmitFeedback = useCallback(
    (value: FeedbackModel) => {
      submitFeedback(message.id, value);
      setShowFeedback(false);
    },
    [message.id, submitFeedback]
  );

  useEffect(() => {
    if (message.feedback?.isPositive === true) {
      setLike(true);
      setDisLike(false);
    }

    if (message.feedback?.isPositive === false) {
      setLike(false);
      setDisLike(true);
    }

    if (message.feedback?.isPositive === null || message.feedback?.isPositive === undefined) {
      setLike(false);
      setDisLike(false);
    }

    dispatch(setLastAnswerId(message.id));
  }, [message, dispatch]);

  return (
    <>
      <Box sx={styles.answer} data-testid="answer">
        <ProfileImage noImage={NO_IMAGE} />
        <Box sx={styles.text}>
          <MarkDown>{message.text || DEFAULT_TEXT}</MarkDown>
          <Box sx={styles.answerBottom}>
            <Box sx={styles.feedback}>
              {!disabled && (
                <>
                  <IconButton onClick={handleLike}>
                    {like ? <ThumbUpIcon fontSize="small" /> : <ThumbUpOutlinedIcon fontSize="small" />}
                  </IconButton>
                  <IconButton onClick={handleDisLike}>
                    {disLike ? <ThumbDownIcon fontSize="small" /> : <ThumbDownAltOutlinedIcon fontSize="small" />}
                  </IconButton>
                </>
              )}
              <IconButton onClick={handleCopy}>
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box sx={styles.navigator}>
              {showPaginator ? (
                <>
                  <IconButton onClick={previous} disabled={isLoading}>
                    <ChevronLeftIcon />
                  </IconButton>
                  <Box sx={styles.pagination}>{`${activePageNumber} / ${length}`}</Box>
                  <IconButton onClick={next} disabled={isLoading}>
                    <ChevronRightIcon />
                  </IconButton>
                </>
              ) : null}
              {!disabled && (
                <IconButton onClick={regenerate} disabled={isLoading}>
                  <RefreshOutlinedIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {showFeedback && <Feedback onClose={onCloseFeedback} onSubmit={handleSubmitFeedback} />}
    </>
  );
};

export default Answer;
