import request, { Methods } from 'utils/request';
import { EntityService } from 'utils/entityService';
import { wait } from 'utils/wait';
import { ProfileModel } from 'models/profile.model';
import { LoginModel } from 'models';

class AuthService extends EntityService {
  constructor() {
    super('auth');
  }

  async login(data: LoginModel) {
    return request<void>({
      method: Methods.POST,
      resource: 'auth',
      data,
    });
  }

  async logout() {
    return request<void>({
      resource: 'auth/logout',
    });
  }

  async refreshToken() {
    return request<void>({
      resource: 'auth/refresh-token',
    });
  }

  async fetchUser() {
    return request<ProfileModel>({
      resource: 'users/me',
    });
  }

  async getConsentUrls() {
    return wait({
      data: {
        privacyPolicy: 'https://www.gstatic.com/policies/privacy/pdf/20200828/k0fdi77r/google_privacy_policy_hu_eu.pdf',
        termsAndConditions:
          'https://www.gstatic.com/policies/terms/pdf/20200331/ba461e2f/google_terms_of_service_hu_eu.pdf',
      },
    });
  }

  async checkConsent() {
    return wait({ data: { hasNewConsent: false } });
  }
}

export const authService = new AuthService();
