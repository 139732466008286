import React, { FC, useCallback, useEffect, useState } from 'react';

import { DiscourseModel, FeedbackModel } from 'models';
import Discourse from './Discourse';

interface ChatProps {
  messages: DiscourseModel[];
  regenerate: (discourseId: string) => void;
  editQuestion: (discourseId: string | null, text: string) => void;
  submitFeedback: (discourseId: string, feedback: FeedbackModel) => void;
  disabled?: boolean;
}

const Chat: FC<ChatProps> = ({ messages, regenerate, editQuestion, submitFeedback, disabled }) => {
  const [index, setIndex] = useState<number>(0);
  const [firstQuestionsId, setFirstQuestionsId] = useState<string[]>([]);

  const next = useCallback(() => {
    setIndex((prev) => (firstQuestionsId.length > prev + 1 ? prev + 1 : prev));
  }, [firstQuestionsId.length]);

  const previous = useCallback(() => {
    setIndex((prev) => (prev - 1 >= 0 ? prev - 1 : prev));
  }, []);

  useEffect(() => {
    const ids = messages.filter((message) => message.parentId === null).map((message) => message.id);
    if (JSON.stringify(ids) !== JSON.stringify(firstQuestionsId)) {
      setFirstQuestionsId(ids);
    }
  }, [messages, firstQuestionsId]);

  useEffect(() => {
    if (firstQuestionsId.length > 0) {
      setIndex(firstQuestionsId.length - 1);
    }
  }, [firstQuestionsId]);

  return (
    <Discourse
      messages={messages}
      id={firstQuestionsId[index]}
      regenerate={regenerate}
      next={next}
      previous={previous}
      editQuestion={editQuestion}
      submitFeedback={submitFeedback}
      activeIndex={index}
      length={firstQuestionsId.length}
      disabled={disabled}
    />
  );
};

export default Chat;
