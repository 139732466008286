export interface KnowledgeModel {
  id: string;
  accessGroup: string;
  name: string;
  url: string;
  uploadedBy: string;
  uploadedAt: Date;
}

export interface KnowledgeUploadModel {
  url?: string | null;
  file?: File[] | null;
  accessGroup: string;
  fieldType: KnowledgeFieldType;
}

export enum KnowledgeFieldType {
  URL = 'URL',
  FILE = 'FILE',
}

export interface KnowledgeQueryModel {
  total: number;
  data: KnowledgeModel[];
}
