import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import KnowledgePage from 'pages/Knowledge/KnowledgePage';
import { AuthRoute, NotFound } from 'components';
import { PrivateLayout } from 'pages/App/PrivateLayout';
import ChatPage from 'pages/Chat/ChatPage';
import PromptsPage from 'pages/Prompts/PromptsPage';
import LoginPage from 'pages/Login/LoginPage';
import { filterRoutes } from 'utils/auth';
import { RolesModel } from 'models';

import { routes } from './routes';

export const getRouterConfig = (roles: RolesModel[] | undefined, isLoggedIn: boolean) => {
  const filteredRoutes = filterRoutes(routes, roles);
  const defaultRoute = filteredRoutes?.length ? filteredRoutes[0].link || '/chat' : '/chat';
  const rootDefaultElement = isLoggedIn ? <PrivateLayout /> : <Navigate to={'/login'} />;

  const privateLayoutLoader = (currentRoute: string) => {
    const selectedRoute = filteredRoutes.find((route) => route.path === currentRoute);

    if (selectedRoute && roles) {
      return null;
    }

    throw new Error();
  };

  const errorElement = isLoggedIn ? <NotFound /> : <Navigate to="/login" />;

  const routesArray = [
    {
      path: '/',
      element: rootDefaultElement,
      errorElement,
      children: [
        {
          index: true,
          element: <Navigate to={defaultRoute} />,
        },
        {
          path: 'knowledge',
          element: <KnowledgePage />,
          loader: () => privateLayoutLoader('knowledge'),
        },
        {
          path: 'prompts',
          element: <PromptsPage />,
          loader: () => privateLayoutLoader('prompts'),
        },
        {
          path: 'chat',
          element: <ChatPage />,
          loader: () => privateLayoutLoader('chat'),
        },
        {
          path: 'chat/:chatId',
          element: <ChatPage />,
          loader: () => privateLayoutLoader('chat/:chatId'),
        },
      ],
    },
    {
      path: 'login',
      element: (
        <AuthRoute>
          <LoginPage />
        </AuthRoute>
      ),
    },
    {
      path: '404',
      element: <NotFound />,
    },
  ];

  return createBrowserRouter(routesArray);
};
