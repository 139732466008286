import React, { ReactFragment, ReactNode, ReactPortal } from 'react';

import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';

interface TooltipProps extends Omit<MuiTooltipProps, 'title'> {
  /**
   * Tooltip title.
   */
  title: boolean | ReactFragment | ReactNode | ReactPortal;
}

export const Tooltip = ({ children, title, ...props }: TooltipProps) => {
  return (
    <MuiTooltip data-testid="tooltip" title={<>{title}</>} {...props}>
      <span>{children}</span>
    </MuiTooltip>
  );
};
