import request, { Methods } from 'utils/request';
import { EntityService } from 'utils/entityService';
import { FeedbackModel, DiscourseModel, DiscoursesResponse } from 'models';

class DiscoursesService extends EntityService {
  constructor() {
    super('discourses');
  }

  async createDiscourses(id: string, question: string, discourseId?: string | null) {
    return request<DiscoursesResponse>({
      method: Methods.POST,
      resource: `${this.resource}/${id}`,
      data: {
        question,
        discourseId,
      },
    });
  }

  async regenerateAnswer(id: string, discourseId?: string) {
    return request<DiscoursesResponse>({
      method: Methods.POST,
      resource: `${this.resource}/${id}/answer`,
      data: {
        discourseId,
      },
    });
  }

  async editQuestion(id: string, question: string, discourseId?: string) {
    return request<DiscoursesResponse>({
      method: Methods.POST,
      resource: `${this.resource}/${id}/question`,
      data: {
        question,
        discourseId,
      },
    });
  }

  async createFeedback(id: string, data: FeedbackModel) {
    return request<DiscourseModel>({
      method: Methods.POST,
      resource: `${this.resource}/feedback/${id}`,
      data,
    });
  }
}

export const discoursesService = new DiscoursesService();
