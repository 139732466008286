import React, { FC, forwardRef, ReactNode } from 'react';
import { Trans } from 'react-i18next';

import MuiTablePagination, { LabelDisplayedRowsArgs, TablePaginationBaseProps } from '@mui/material/TablePagination';
import { SelectProps } from '@mui/material/Select';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { IconButtonProps } from '@mui/material/IconButton';

import { Env } from 'config/env';
import { formatNumber } from 'utils/format';

export type OnPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
export type OnRowsPerPageChange = React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;

export interface TablePaginationProps extends TablePaginationBaseProps {
  innerRef?: React.Ref<any>;
  ActionsComponent?: React.ElementType<TablePaginationActionsProps>;
  backIconButtonProps?: Partial<IconButtonProps>;
  count?: number;
  labelDisplayedRows?: (paginationInfo: LabelDisplayedRowsArgs) => ReactNode;
  labelRowsPerPage?: string;
  nextIconButtonProps?: Partial<IconButtonProps>;
  onPageChange: OnPageChange;
  onRowsPerPageChange?: OnRowsPerPageChange;
  page?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: Array<number | { value: number; label: string }>;
  SelectProps?: Partial<SelectProps>;
  component?: React.ComponentType<any> | string;
}

export const getDisplayedRowsLabel = ({ from, to, count }: LabelDisplayedRowsArgs) =>
  `${formatNumber(from) || 0} - ${formatNumber(to) || 0} / ${formatNumber(count) || 0}`;

export const TablePagination: FC<TablePaginationProps> = forwardRef(
  ({ labelRowsPerPage, labelDisplayedRows, rowsPerPageOptions, page, rowsPerPage, count, ...props }, ref) => {
    const adjustedPage = (page || 1) - 1;

    return (
      <MuiTablePagination
        data-testid="table-pagination"
        ref={ref}
        rowsPerPageOptions={rowsPerPageOptions || Env.ROWS_PER_PAGE_OPTIONS}
        count={count || 0}
        page={!count && adjustedPage > 0 ? 0 : adjustedPage}
        rowsPerPage={rowsPerPage || Env.ROWS_PER_PAGE_OPTIONS[0]}
        labelRowsPerPage={labelRowsPerPage || <Trans i18nKey="COMMON.ROWS_PER_PAGE" />}
        labelDisplayedRows={labelDisplayedRows || getDisplayedRowsLabel}
        {...props}
      />
    );
  }
);
