import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Notification, LoadingOverlay } from 'components';
import { selectLoaderState } from 'store/common';
import { logout, selectIsLoggedIn } from 'store/auth';
import { getProfile, selectUserRoles } from 'store/profile';
import { useAppDispatch } from 'utils/useAppDispatch';
import { compose } from 'utils/functions';

import { authService } from 'services';

import { getRouterConfig } from 'config/routerConfig';
import { ConsentDialog } from './ConsentDialog';

const App: FC = () => {
  const dispatch = useAppDispatch();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const loader = useSelector(selectLoaderState);
  const role = useSelector(selectUserRoles);

  const [isProfileLoading, setProfileLoading] = useState(true);
  const [isConsentLoading, setConsentLoading] = useState(true);
  const [consentDialogOpen, setConsentDialogOpen] = useState(false);

  const isLoading = useMemo(() => isProfileLoading || isConsentLoading, [isProfileLoading, isConsentLoading]);

  const initProfile = useCallback(async () => {
    try {
      setProfileLoading(true);
      await dispatch(getProfile());
    } finally {
      setProfileLoading(false);
    }
  }, [dispatch]);

  const checkConsent = useCallback(async () => {
    try {
      setConsentLoading(true);

      if (!isLoggedIn) {
        return;
      }

      const consent = await authService.checkConsent();

      if (consent?.hasNewConsent) {
        setConsentDialogOpen(true);
      }
    } finally {
      setConsentLoading(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    initProfile();
  }, [initProfile]);

  useEffect(() => {
    checkConsent();
  }, [checkConsent]);

  const onConsentDialogClose = () => {
    setConsentDialogOpen(false);
  };

  const onConsentDialogCancel = () => {
    onConsentDialogClose();
    dispatch(logout());
  };

  const router = useMemo(() => {
    return getRouterConfig(role, isLoggedIn);
  }, [isLoggedIn, role]);

  return (
    <>
      <LoadingOverlay show={loader.show || isLoading} fixed />

      <Notification />

      <ConsentDialog onCancel={onConsentDialogCancel} onClose={onConsentDialogClose} isOpen={consentDialogOpen} />

      {isLoading ? null : <RouterProvider router={router} />}
    </>
  );
};

export default compose(withTranslation())(App);
