import qs from 'query-string';
import isArray from 'lodash/isArray';

import { ImgSrcSetLoader, Size } from 'models';

export const defaultImgSrcLoader: ImgSrcSetLoader = ({ src = '', width, quality }) =>
  `${src}?${qs.stringify({ w: width, q: quality })}`;

const HEIGHT_UNIT = 100;

export const getSrcSet = ({
  src,
  srcSet,
  imageSizes,
  loader,
  quality,
  noLoader,
}: {
  src?: string;
  srcSet?: string;
  quality?: number;
  loader: ImgSrcSetLoader;
  imageSizes: number[];
  noLoader?: boolean;
}) => {
  if (srcSet) {
    return srcSet;
  }

  if (noLoader) {
    return undefined;
  }

  return getLoadedImageSrcset({
    sizes: imageSizes,
    getFileName: (size) => loader({ src, width: size, quality }),
    sizePostfix: 'w',
  });
};

const getLoadedImageSrcset = ({
  sizes,
  sizePostfix,
  getFileName,
}: {
  sizes: number[];
  sizePostfix: string;
  getFileName: (size: number) => string;
}) => sizes.map((size) => `${getFileName(size)} ${size}${sizePostfix}`).join(', ');

export const getSizes = (sizes?: string | Size[]) => {
  if (isArray(sizes)) {
    return sizes.map((size) => getSize(size)).join(', ');
  }
  return sizes;
};

const getSize = ({ mediaCondition, vw }: Size) => {
  const vwWithUnit = `${vw}vw`;

  if (mediaCondition) {
    return `${getBracketed(mediaCondition)} ${vwWithUnit}`;
  }
  return vwWithUnit;
};

const getBracketed = (item: string) => `(${item})`;

export const getDimensionFromRatio = (ratio: number, baseUnit = HEIGHT_UNIT) => {
  return { width: ratio * baseUnit, height: baseUnit };
};
