export enum FieldType {
  STRING = 'STRING',
  COLOR = 'COLOR',
  BOOLEAN = 'BOOLEAN',
  URL = 'URL',
  ARRAY = 'ARRAY',
  FILE = 'FILE',
}

export interface SettingModel {
  key: string;
  value: string;
  fieldType: FieldType;
  description?: string;
  settingAsset: string;
  id: string | null;
  userId?: string;
  sourcePropertyId?: string;
  inheritedFromParentAsset: boolean;
  overridable: boolean;
}

export interface SettingQueryModel {
  totalCount: number;
  page: number;
  results: SettingModel[];
}
