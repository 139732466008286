import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { AccessRestriction, hasPermission } from 'utils/auth';
import { selectUserRoles } from 'store/profile';

interface AccessControllerProps extends AccessRestriction {
  /**
   * The content to show, when the user does not have the correct permissions.
   */
  noAccessChildren?: ReactNode;
}

export const AccessController: FC<PropsWithChildren<AccessControllerProps>> = ({
  allowedFor,
  children,
  noAccessChildren,
}) => {
  const roles = useSelector(selectUserRoles);

  if (!hasPermission(roles, allowedFor)) {
    return <>{noAccessChildren || null}</>;
  }

  return <>{children}</>;
};
