import i18n from 'i18n';

import { PersonModel } from 'models';

interface FormatOptions {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  currency?: string;
  postfix?: string;
}

function isIntlSupported() {
  // Check if browser supports Intl API
  return typeof Intl === 'object' && Intl && typeof Intl.NumberFormat === 'function';
}

export function formatNumber(
  num?: number | string | null,
  { minimumFractionDigits = 0, maximumFractionDigits = 0, currency, postfix }: FormatOptions = {}
) {
  let value: number | string = typeof num === 'string' ? parseInt(num, 10) : num || 0;

  // Use Browser's Intl for nicer formatting
  if (isIntlSupported()) {
    value = value.toLocaleString(i18n.language, {
      currency,
      style: currency ? 'currency' : 'decimal',
      maximumFractionDigits,
      minimumFractionDigits: currency ? maximumFractionDigits : minimumFractionDigits,
    });
  } else {
    value = value
      .toFixed(maximumFractionDigits)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

    if (currency) {
      value = `${value} ${currency}`;
    }
  }

  if (postfix) {
    return `${value} ${postfix}`;
  }

  return value;
}

export const parseStringAsInt = <T extends string>(value: T | T[]) => {
  if (Array.isArray(value)) {
    return value.map((val) => parseInt(val, 10));
  }

  return parseInt(value, 10);
};

export function floorTo(value: number, to = 10) {
  return to * Math.floor(value / to);
}

export function ceilTo(value: number, to = 10) {
  return to * Math.ceil(value / to);
}

export function toInteger(value: any) {
  const parsed = parseInt(value, 10);

  return Number.isNaN(parsed) ? 0 : parsed;
}

export function toBoolean(value: any) {
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
      return true;
    default:
      return false;
  }
}

export function formatProfileName(profile?: PersonModel | null) {
  if (!profile) {
    return '';
  }

  if (profile.name) {
    return profile.name;
  }

  return profile.email;
}
