import React, { FC } from 'react';
import { Box } from '@mui/material';

import logo from 'assets/images/attrecto-colored.png';

const styles = {
  container: {
    width: '100%',
  },
  attrectoLogo: {
    m: 2,
    opacity: 0.3,
  },
  assistantLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
    opacity: 0.6,
  },
  assistantLogo: {
    position: 'absolute',
    top: '50%',
    display: 'flex',
  },
  title: {
    ml: 1,
    fontSize: 40,
  },
};

const Logo: FC = () => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.attrectoLogo}>
        <img src={logo} alt="logo" style={{ height: 'inherit' }} />
      </Box>
    </Box>
  );
};

export default Logo;
