export type RemoveFalsyPredicate = <P extends Record<string, any>>(o: P, k: keyof P) => boolean;

export function removeFalsy<P extends Record<string, any>>(obj: P, predicate?: RemoveFalsyPredicate) {
  const newObj = {} as P;
  const keys = Object.keys(obj) as Array<keyof P>;

  const isTruthy = predicate || ((o: P, k: keyof P) => o[k] || o[k] === 0);

  keys.forEach((prop) => {
    if (isTruthy(obj, prop)) {
      newObj[prop] = obj[prop];
    }
  });

  return newObj;
}

export function removeProp<P extends Record<string, any>>(obj: P, prop: keyof P | string) {
  return Object.keys(obj).reduce((result, key) => {
    if (key !== prop) {
      result[key as keyof P] = obj[key as keyof P];
    }

    return result;
  }, {} as P);
}
