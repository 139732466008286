import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface ActionMenuProps {
  openDeleteDialog: () => void;
  openEditDialog: () => void;
  openShareDialog: () => void;
}

const styles = {
  menuIcon: {
    marginRight: 1,
  },
  menuItem: {
    display: 'flex',
  },
};

export const ActionMenu: FC<ActionMenuProps> = ({ openDeleteDialog, openEditDialog, openShareDialog }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickAndClose = (
    e: React.MouseEvent<HTMLElement | HTMLButtonElement>,
    anchorElement: HTMLElement | null = null
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(anchorElement);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleClickAndClose(event, event.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    handleClickAndClose(e);
  };

  const onDeleteClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    openDeleteDialog();
    handleClose(e);
  };

  const onEditClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    openEditDialog();
    handleClose(e);
  };

  const onShareClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    openShareDialog();
    handleClose(e);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onEditClick}>
          <Typography sx={styles.menuItem}>
            <EditIcon fontSize="small" sx={styles.menuIcon} /> <Trans i18nKey="COMMON.EDIT" />
          </Typography>
        </MenuItem>
        <MenuItem onClick={onShareClick}>
          <Typography sx={styles.menuItem}>
            <ShareIcon fontSize="small" sx={styles.menuIcon} /> <Trans i18nKey="CHAT.SHARE" />
          </Typography>
        </MenuItem>
        <MenuItem onClick={onDeleteClick}>
          <Typography sx={styles.menuItem}>
            <DeleteIcon fontSize="small" sx={styles.menuIcon} /> <Trans i18nKey="COMMON.DELETE" />
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
