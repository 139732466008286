import { useEffect } from 'react';

export interface BeforeUnloadParams {
  unloadHandler: (e: Event) => void;
  showDialog: boolean;
}

export const useBeforeUnload = ({ unloadHandler, showDialog }: BeforeUnloadParams) => {
  useEffect(() => {
    const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
      if (showDialog) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('unload', unloadHandler);
    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      window.removeEventListener('unload', unloadHandler);
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [unloadHandler, showDialog]);
};
