import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';

import MuiTableCell, { TableCellProps as MuiTableCellProps } from '@mui/material/TableCell';

import { mergeSx } from 'utils/styles';
import theme from 'theme';

const styles = {
  noWrap: {
    whiteSpace: 'nowrap',
    maxWidth: theme.mixins.panel.width,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  linkCell: {
    padding: 0,
    '&.MuiTableCell-sizeSmall': {
      '& $link': {
        padding: '6px 24px 6px 16px',
      },
    },
    '&.MuiTableCell-paddingCheckbox': {
      '& $link': {
        padding: '0 0 0 4px',
      },
    },
    '& a': {
      textDecoration: 'none',
      display: 'flex',
      color: 'inherit',
      padding: 2,
      textAlign: 'inherit',
      flexDirection: 'inherit',
    },
  },
};

export interface TableLinkProps {
  link?: boolean;
  to?: string;
  replace?: boolean;
}

export interface TableCellProps extends MuiTableCellProps, TableLinkProps {
  noWrap?: boolean;
}

export const TableCell: FC<TableCellProps> = memo(({ noWrap, children, link, to, replace, sx, ...props }) => {
  return (
    <MuiTableCell sx={mergeSx(noWrap ? styles.noWrap : null, link && to ? styles.linkCell : null, sx)} {...props}>
      {link && to ? (
        <Link to={to} replace={replace}>
          {children}
        </Link>
      ) : (
        children
      )}
    </MuiTableCell>
  );
});

export default TableCell;
