import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const styles = {
  root: {
    width: '80%',
    height: '100vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};

export const NotFound: FC = () => {
  return (
    <Box data-testid="not-found" sx={styles.root}>
      <Typography variant="h4">
        <Trans i18nKey="NOT_FOUND.TITLE" />
      </Typography>
      <Typography>
        <Trans i18nKey="NOT_FOUND.DESCRIPTION" />
      </Typography>
    </Box>
  );
};
