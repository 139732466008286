import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel, ConversationModel } from 'models';
import { conversationService } from 'services';

type UserQueryContexts = QueryContextFromKeys<typeof keys>;

export const keys = {
  all: [{ scope: 'conversation' }] as const,
  lists: () => [{ ...keys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...keys.lists()[0], params }] as const,
};

const fetchConversationsList = async ({ queryKey: [{ params }] }: UserQueryContexts['list']) =>
  conversationService.getConversations(params);

export const useConversationsList = (params?: QueryParamModel | null) =>
  useQuery(keys.list(params), fetchConversationsList);

const fetchInfiniteConversationsList = async ({ queryKey: [{ params }], pageParam = 1 }: UserQueryContexts['list']) =>
  conversationService.getConversations({ ...params, page: pageParam });

export const useInfiniteConversationsList = (params?: QueryParamModel | null) =>
  useInfiniteQuery(keys.list(params), fetchInfiniteConversationsList, {
    getNextPageParam: (_, allPages) =>
      allPages.reduce((acc, cur) => acc + cur.data.length, 0) < allPages[0].total ? allPages.length + 1 : undefined,
  });

export const useDeleteConversation = () => {
  const queryClient = useQueryClient();

  return useMutation((id: string) => conversationService.deleteConversation(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.lists() });
    },
  });
};

export const useEditConversation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: ConversationModel) => conversationService.editConversation(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.lists() });
    },
  });
};

export const useCreateConversation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: Partial<ConversationModel>) => conversationService.createConversation(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.lists() });
    },
  });
};
