import React, { ElementType, FC, ReactNode, SyntheticEvent } from 'react';

import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { SxProps, Theme } from '@mui/material/styles';

import { mergeSx } from 'utils/styles';

const styles = {
  hidden: {
    visibility: 'hidden',
  },
  rootComponent: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  title: {
    zIndex: 100,
    background: 'background.paper',
  },
  titleText: {
    width: '100%',
  },
  titleWithClose: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: (theme: Theme) => theme.spacing(1, 2, 1, 3),
  },
};

export interface BaseDialogProps {
  /**
   * The header of the dialog.
   */
  title?: ReactNode;
  /**
   * If set to ```true``` the component is shown.
   * @default false
   */
  open: boolean;
  /**
   * If set to ```true``` a progress bar appears in the header of the dialog.
   * @default false
   */
  loading?: boolean;
  /**
   * Callback fired when the component requests to be closed.
   * @param event — The event source of the callback.
   * @param reason — Can be: "escapeKeyDown", "backdropClick".
   */
  onClose: (e?: SyntheticEvent<any>, reason?: 'backdropClick' | 'escapeKeyDown') => void;
}

export interface DialogProps extends Omit<MuiDialogProps, 'title' | 'onClose'>, BaseDialogProps {
  /**
   * The dialog actions.
   */
  actions?: ReactNode;
  /**
   * The system prop that is applied directly to the content element.
   */
  contentSx?: SxProps<Theme>;
  /**
   * The class(es) that is/are directly applied to the button (in case if you prefer classes over sx).
   */
  contentClassName?: string;
  /**
   * If set to ```true```, the close button in the top right corner of the dialog does not appear.
   */
  hideCloseButton?: boolean;
  children?: ReactNode;
  /**
   * The root component of the dialog.
   * @default "div"
   */
  component?: ElementType;
}

export const Dialog: FC<DialogProps> = ({
  open,
  component,
  onClose,
  actions,
  title,
  children,
  contentSx,
  contentClassName,
  hideCloseButton,
  loading,
  maxWidth,
  fullWidth,
  ...props
}) => {
  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const RootComponent = component || 'div';
  const showCloseButton = Boolean(!hideCloseButton && onClose);

  const handleClose = (e: SyntheticEvent<any>, reason?: 'backdropClick' | 'escapeKeyDown') => {
    onClose(e, reason);
  };

  return (
    <MuiDialog
      maxWidth={maxWidth || 'sm'}
      fullWidth={fullWidth !== false}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      {...props}
    >
      <RootComponent sx={styles.rootComponent} data-testid="dialog-root-component">
        <LinearProgress data-testid="dialog-loading" sx={mergeSx(!loading ? styles.hidden : null)} />
        <DialogTitle sx={mergeSx(styles.title, showCloseButton ? styles.titleWithClose : null)}>
          <Typography sx={styles.titleText} variant="h6" component="p" role="title">
            {title}
          </Typography>

          {showCloseButton ? (
            <IconButton onClick={onClose} edge="end" role="close" size="large">
              <ClearIcon color="action" />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent sx={contentSx} className={contentClassName}>
          {children}
        </DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </RootComponent>
    </MuiDialog>
  );
};
