import request from 'utils/request';

import { EntityService } from 'utils/entityService';
import { ProfileModel } from 'models/profile.model';
import { QueryParamModel } from 'models';

class UserService extends EntityService {
  constructor() {
    super('users');
  }

  async getProfile() {
    return request<ProfileModel>({
      resource: 'users/me',
    });
  }

  async getUsers(params?: QueryParamModel | null) {
    return request<ProfileModel[]>({
      resource: 'users',
      params,
    });
  }
}

export const userService = new UserService();
