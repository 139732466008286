import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';

import CodeBlock from './CodeBlock';

interface MarkdownProps {
  children: string;
}

const MarkDown: FC<MarkdownProps> = ({ children }) => {
  return <ReactMarkdown components={{ code: CodeBlock }}>{children}</ReactMarkdown>;
};

export default MarkDown;
