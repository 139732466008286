import React, { FC, useContext } from 'react';

import TableSortLabel from '@mui/material/TableSortLabel';

import { SortDirection } from 'utils/arrays';

import { TableCell, TableCellProps } from './TableCell';
import { TableContext } from './TableRoot';

export interface TableHeaderCellProps extends TableCellProps {
  field?: string | null;
}

const styles = {
  active: {
    '&.Mui-active': {
      color: 'action.active',
    },
  },
};

export const getOrder = (active: boolean, order?: SortDirection | null) => {
  if (!active) {
    return SortDirection.ASC;
  }

  if (order === SortDirection.ASC) {
    return SortDirection.DESC;
  }

  return SortDirection.ASC;
};

export const TableHeaderCell: FC<TableHeaderCellProps> = ({ field, children, noWrap, ...props }) => {
  const { onSort, sortBy, orderBy } = useContext(TableContext);

  const active = sortBy === field;

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (onSort) {
      const nextOrder = getOrder(active, orderBy);

      onSort(event, property, nextOrder);
    }
  };

  const direction = active ? orderBy || undefined : 'asc';

  return (
    <TableCell sortDirection={orderBy && sortBy === field ? orderBy : false} noWrap={noWrap !== false} {...props}>
      {field ? (
        <TableSortLabel
          data-testid="table-sort-label"
          active={active}
          sx={styles.active}
          direction={direction}
          onClick={createSortHandler(field)}
        >
          {children}
        </TableSortLabel>
      ) : (
        children
      )}
    </TableCell>
  );
};

export default TableHeaderCell;
