import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box } from '@mui/material';

import { Button, Dialog, DialogProps, ControlledTextField, showNotification, Form } from 'components';
import { NotificationType, PromptsModel } from 'models';

const styles = {
  buttonContainer: {
    width: '100%',
    display: 'flex',
  },
  button: {
    marginLeft: 'auto',
  },
};

export interface PromptsModalProps extends DialogProps {
  edit: (values: PromptsModel) => Promise<PromptsModel>;
  prompt: PromptsModel | null;
}

const PromptsModal: FC<PromptsModalProps> = ({ edit, onClose, prompt, ...props }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const initialValues: PromptsModel = { key: prompt?.key || '', value: prompt?.value || '' };

  const validationSchema = Yup.object().shape({
    key: Yup.string().required(),
    value: Yup.string().required(),
  });

  const { handleSubmit, control } = useForm({
    values: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: PromptsModel) => {
    setLoading(true);
    try {
      await edit(values);
      onClose();
      showNotification({ content: t('COMMON.OPERATION_SUCCESSFUL'), type: NotificationType.SUCCESS });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog title={initialValues.key} onClose={onClose} maxWidth={'lg'} {...props}>
      <Form onSubmit={handleSubmit(onSubmit)} control={control} placement="top-left">
        <ControlledTextField control={control} name="value" label={t('PROMPTS.VALUE')} multiline rows={15} />
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.button} onClick={handleSubmit(onSubmit)} loading={loading}>
            <Trans i18nKey="COMMON.SAVE" />
          </Button>
        </Box>
      </Form>
    </Dialog>
  );
};

export default PromptsModal;
