import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, ListItemButton, Theme } from '@mui/material';

import { selectIsLoading } from 'store/chat';
import { ConversationModel } from 'models';
import { Tooltip } from 'components/tooltip/Tooltip';
import { ActionMenu } from 'components/menu/ActionMenu';

const styles = {
  rootLink: {
    color: 'dark.contrastText',
    backgroundColor: 'dark.main',
    pt: 1.5,
    pb: 1.5,
    paddingLeft: (theme: Theme) => `${parseInt(theme.spacing(2), 10) - theme.shape.normalIndicatorSize}px`,
    borderLeft: (theme: Theme) => `${theme.shape.normalIndicatorSize}px solid transparent`,
    transition: "transitions.create('border-left-color')",
    '&:hover, &.active': {
      borderLeftColor: 'dark.light',
      backgroundColor: 'action.hoverDark',
      color: 'dark.contrastText',
    },
  },
  icon: {
    color: 'dark.light',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  buttons: {
    ml: 'auto',
    display: 'flex',
  },
};

export interface SideBarLinkProps {
  conversation: ConversationModel;
  showDelete?: (conversation: ConversationModel) => void;
  showShare?: (conversation: ConversationModel) => void;
  showEdit?: (conversation: ConversationModel) => void;
}

const ChatLink: FC<SideBarLinkProps> = ({ conversation, showDelete, showShare, showEdit }) => {
  const link = `chat/${conversation.id}`;
  const title = conversation.title || '';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const isLoading = useSelector(selectIsLoading);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const showActionMenu = useMemo(() => showShare && showDelete && showEdit, [showShare, showDelete, showEdit]);

  const showShareModal = () => {
    if (showShare) {
      showShare(conversation);
    }
  };

  const showDeleteModal = () => {
    if (showDelete) {
      showDelete(conversation);
    }
  };

  const showEditModal = () => {
    if (showEdit) {
      showEdit(conversation);
    }
  };

  const onClick = (e: any) => {
    e.preventDefault();

    if (isLoading) {
      // eslint-disable-next-line no-alert
      if (window.confirm(t('ERRORS.SSE_IN_PROGRESS'))) {
        navigate(link);
      }
    } else {
      navigate(link);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const titleElement = container.querySelector('.MuiBox-root ');
      if (titleElement) {
        if (titleElement.scrollWidth > container.clientWidth) {
          setIsTooltipVisible(true);
        } else {
          setIsTooltipVisible(false);
        }
      }
    }
  }, [title]);

  return (
    <ListItemButton component={NavLink} disableRipple to={link} sx={styles.rootLink} onClick={onClick}>
      <Box sx={styles.title} ref={containerRef}>
        {isTooltipVisible ? (
          <Tooltip title={title}>
            <Box sx={styles.title}>{title}</Box>
          </Tooltip>
        ) : (
          <Box sx={styles.title}>{title}</Box>
        )}
      </Box>
      {showActionMenu && (
        <Box sx={styles.buttons}>
          <ActionMenu
            openDeleteDialog={showDeleteModal}
            openEditDialog={showEditModal}
            openShareDialog={showShareModal}
          />
        </Box>
      )}
    </ListItemButton>
  );
};

export default ChatLink;
