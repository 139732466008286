import React, { FC, createContext, ReactNode, useMemo } from 'react';
import { Trans } from 'react-i18next';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MuiTable, { TableProps as MuiTableProps } from '@mui/material/Table';

import { QueryParamModel } from 'models';
import { OnSortFunction, LoadingOverlay } from 'components';
import { SortDirection } from 'utils/arrays';
import { mergeSx } from 'utils/styles';

import { TablePagination, OnPageChange, OnRowsPerPageChange, TablePaginationProps } from './TablePagination';

const styles = {
  root: {
    overflowX: 'auto',
    flex: 1,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    position: 'relative',
    marginBottom: 2,
    flex: 1,
  },
  paperLoading: {
    height: 40,
    overflowY: 'hidden',
    boxShadow: 'none',
  },
  pagination: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableOverlay: {
    filter: 'blur(2px)',
    pointerEvents: 'none',
  },
};

export interface TableContextValues {
  onSort?: OnSortFunction | null;
  sortBy?: string | null;
  orderBy?: SortDirection | null;
}

export const TableContext = createContext<TableContextValues>({
  sortBy: null,
  orderBy: null,
  onSort: null,
});

export interface BaseTableRootProps extends MuiTableProps, TableContextValues {
  /**
   * The query paramater for the table (page size, order, etc.).
   */
  params?: QueryParamModel;
  /**
   * Custom message if the table is empty.
   */
  emptyState?: ReactNode;
  /**
   * If set to ```true```, the loading spinner shows up.
   */
  loading?: boolean;
  /**
   * The total length of the list.
   */
  total?: number;
  /**
   * Callback fired when the page is changed.
   */
  onPageChange: OnPageChange;
  /**
   * Callback fired when the number of rows per page is changed.
   */
  onRowsPerPageChange?: OnRowsPerPageChange;
  /**
   * Props applied to the pagination element.
   */
  pagination?: TablePaginationProps;
  isLoadingDelete?: boolean;
}

export interface TableRootProps extends BaseTableRootProps {
  count?: number;
  disableStickyHeader?: boolean;
}

export const TableRoot: FC<TableRootProps> = ({
  children,
  onSort,
  sortBy,
  orderBy,
  emptyState,
  loading,
  count,
  onPageChange,
  onRowsPerPageChange,
  total,
  params,
  pagination,
  disableStickyHeader,
  isLoadingDelete,
  ...props
}) => {
  const tableContextValue = useMemo(
    () => ({ onSort, sortBy: sortBy || params?.sortBy, orderBy: orderBy || params?.orderBy }),
    [onSort, orderBy, params?.orderBy, params?.sortBy, sortBy]
  );

  return (
    <TableContext.Provider value={tableContextValue}>
      <Paper
        data-testid="table-root"
        square
        sx={mergeSx(styles.paper, loading ? styles.paperLoading : null, isLoadingDelete ? styles.tableOverlay : null)}
      >
        <LoadingOverlay show={loading} />
        {!loading && count ? (
          <>
            <Box sx={styles.root}>
              <MuiTable {...props} stickyHeader={!disableStickyHeader}>
                {children}
              </MuiTable>
            </Box>
            <TablePagination
              {...(pagination || {})}
              sx={styles.pagination}
              count={total}
              page={params?.page}
              rowsPerPage={params?.pageSize}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              component="div"
            />
          </>
        ) : null}

        {!loading && !count ? (
          <Box p={2}>
            <Typography variant="h5">{emptyState || <Trans i18nKey="COMMON.TABLE_EMPTY_MESSAGE" />}</Typography>
          </Box>
        ) : null}
      </Paper>
    </TableContext.Provider>
  );
};
