import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Button, ConfirmDialog, Page, useDialogState, useTableQueryParams } from 'components';
import { KnowledgeFieldType, KnowledgeUploadModel } from 'models';
import { SortDirection } from 'utils/arrays';
import { useCreateKnowledge, useDeleteKnowledges, useKnowledgesList } from 'features/knowledges';
import KnowledgesTable from './KnowledgesTable';
import { KnowledgeModal } from './KnowledgeModal';

const styles = {
  buttonContainer: {
    marginBottom: 2,
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const KnowledgePage: FC = () => {
  const { t } = useTranslation();
  const title = `${t('KNOWLEDGE.TITLE')}`;

  const { onPageChange, onRowsPerPageChange, onSort, params } = useTableQueryParams({
    sortBy: 'name',
    orderBy: SortDirection.ASC,
  });

  const {
    isOpen: isEditorOpen,
    closeDialog: closeEditor,
    openEmptyDialog: openEmptyEditor,
  } = useDialogState<KnowledgeUploadModel>();

  const {
    isOpen: isDeleteOpen,
    closeDialog: closeDelete,
    openEmptyDialog: openDelete,
  } = useDialogState<KnowledgeUploadModel>();

  const { data: knowledges, isLoading: isLoadingList } = useKnowledgesList({
    ...params,
  });

  const { mutateAsync: deleteKnowledges } = useDeleteKnowledges();
  const { mutateAsync: createKnowledge } = useCreateKnowledge();

  const onCreate = async (values: KnowledgeUploadModel) => {
    const formData = new FormData();
    if (values.fieldType === KnowledgeFieldType.URL && values.url) {
      formData.append('url', values.url);
    }
    if (values.fieldType === KnowledgeFieldType.FILE && values.file) {
      formData.append('file', values.file[0]);
    }

    formData.append('accessGroup', values.accessGroup || '');

    await createKnowledge(formData);
  };

  const onDelete = async () => {
    await deleteKnowledges();
  };

  return (
    <Page title={title}>
      <Box sx={styles.buttonContainer}>
        <Button onClick={openDelete} color="error">
          <Trans i18nKey="KNOWLEDGE.ALL_DELETE" />
        </Button>
        <Button onClick={openEmptyEditor}>
          <Trans i18nKey="KNOWLEDGE.CREATE" />
        </Button>
      </Box>

      <ConfirmDialog open={isDeleteOpen} onSubmit={onDelete} onClose={closeDelete}>
        <Trans i18nKey="KNOWLEDGE.ALL_DELETE_QUESTION" />
      </ConfirmDialog>

      <KnowledgeModal open={isEditorOpen} onClose={closeEditor} create={onCreate} />

      <KnowledgesTable
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onSort={onSort}
        params={params}
        isLoading={isLoadingList}
        knowledges={knowledges}
      />
    </Page>
  );
};

export default KnowledgePage;
