import React, { FC, ReactNode } from 'react';

import Container from '@mui/material/Container';

import { Header, LoadingOverlay, Panel } from 'components';
import { PanelConfigModel, SxProp } from 'models';
import { useEffectOnce } from 'utils/useEffectOnce';
import { mergeSx } from 'utils/styles';

import { PageContent } from './PageContent';

interface PanelConfigProps {
  /**
   * Panels that can slide into the page.
   */
  panels?: (PanelConfigModel | null)[] | null;
  /**
   * The currently active panel.
   */
  activePanel?: string | null;
  /**
   * Additional function for the panels close event.
   */
  closePanel?: () => void;
  /**
   * Sets the active panel.
   */
  setPanel?: (config?: PanelConfigModel | null) => void;
}

interface PageProps extends PanelConfigProps, SxProp {
  /**
   * The title of the page.
   */
  title: ReactNode;
  /**
   * The class(es) (in case if you prefer classes over sx).
   */
  className?: string;
  /**
   * If set to ```true```, the page becames scrollable, regardless of the amount of content.
   */
  inScroll?: boolean;
  /**
   * If set to ```true```, the spinner shows up.
   */
  isLoading?: boolean;
  /**
   * If set to ```true```, the breadcrumb shows up.
   */
  showBreadcrumbs?: boolean;
  children?: ReactNode;
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  inScroll: {
    height: '100vh',
    overflowY: 'auto',
  },
  panel: {
    '& .MuiDrawer-paper': {
      height: '100%',
    },
  },
};

export const Page: FC<PageProps> = ({
  title,
  sx,
  className,
  children,
  inScroll,
  isLoading,
  panels,
  closePanel,
  activePanel,
  setPanel,
  showBreadcrumbs = true,
}) => {
  const hasPanels = Array.isArray(panels) && panels.length;
  const isPanelOpen = Boolean(activePanel);

  useEffectOnce(() => {
    const initialPanel = panels?.find((panel) => panel?.isInitiallyOpen);

    if (initialPanel && !activePanel && setPanel) {
      setPanel(initialPanel);
    }
  });

  return (
    <Container
      data-testid="page"
      maxWidth="xl"
      className={className}
      sx={mergeSx(styles.root, inScroll ? styles.inScroll : null, sx)}
    >
      <LoadingOverlay show={isLoading} />
      <Header title={title} />

      {!isLoading ? (
        <PageContent inScroll={inScroll} isPanelOpen={isPanelOpen} hasPanels={Boolean(hasPanels)}>
          {children}
        </PageContent>
      ) : null}

      {hasPanels ? (
        <Panel isOpen={isPanelOpen} sx={styles.panel} onClose={closePanel}>
          {panels.find((panel) => panel?.key === activePanel)?.content}
        </Panel>
      ) : null}
    </Container>
  );
};
