import React, { FC, KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans } from 'react-i18next';

import { Box } from '@mui/material';

import Yup from 'utils/yup';
import { settings } from 'config/settings';
import { ControlledTextField, Button, Form } from 'components';
import { selectIsLoading } from 'store/chat';
import { ChatInputModel } from 'models';

const MAX_ROWS = 4;

const styles = {
  input: {
    '& .MuiInputBase-input': {
      '::-webkit-scrollbar': {
        width: 8,
      },

      '::-webkit-scrollbar-track': {
        backgroundColor: 'grey.100',
        borderRadius: 2,
      },

      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'grey.400',
        borderRadius: 2,
      },
    },
  },
  modelFooterButtons: {
    mt: 2,
    display: 'flex',
    width: '100%',
    justifyContent: 'right',
  },
};

interface QuestionInputFormProps {
  onClose: () => void;
  onSubmit: (values: ChatInputModel) => void;
  initialText?: string | null;
}

const QuestionInputForm: FC<QuestionInputFormProps> = ({ onClose, initialText, onSubmit }) => {
  const isLoading = useSelector(selectIsLoading);

  const initialValues: ChatInputModel = { text: initialText || '' };

  const validationSchema = Yup.object().shape({
    text: Yup.string().max(settings.MAX_CHARACTER),
  });

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const value = useWatch({ control, name: 'text' });

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();

      if (!isLoading && value?.trim()) {
        handleSubmit(onSubmit)();
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} control={control} placement="top-right">
      <ControlledTextField
        control={control}
        name="text"
        data-testid="question-input"
        sx={styles.input}
        fullWidth
        multiline
        maxRows={MAX_ROWS}
        onKeyDown={handleKeyPress}
      />
      <Box sx={styles.modelFooterButtons}>
        <Box mr={2}>
          <Button variant="outlined" onClick={onClose}>
            <Trans i18nKey="COMMON.CANCEL" />
          </Button>
        </Box>
        <Button onClick={handleSubmit(onSubmit)} disabled={!value?.trim()}>
          <Trans i18nKey="COMMON.SAVE" />
        </Button>
      </Box>
    </Form>
  );
};

export default QuestionInputForm;
