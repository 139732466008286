import React, { FC, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { DialogProps, Dialog, Button, Form, ControlledTextField } from 'components';
import { ConversationModel } from 'models';
import { conversationService } from 'services';
import Yup from 'utils/yup';
import { useEditConversation } from 'features/conversations';
import { useAppDispatch } from 'utils/useAppDispatch';
import { setTitle } from 'store/chat';
import { settings } from 'config/settings';

interface ValueModel {
  title: string;
}

export interface EditDialogProps extends DialogProps {
  selected: ConversationModel | null;
}

const EditDialog: FC<EditDialogProps> = ({ onClose, selected, ...props }) => {
  const dispatch = useAppDispatch();
  const [conversation, setConversation] = useState<ConversationModel | null>();
  const [loading, setLoading] = useState(false);
  const { mutateAsync: edit } = useEditConversation();

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(settings.MAXIMUM_TITLE_LENGTH).required(),
  });

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<ValueModel> = async (value: ValueModel) => {
    setLoading(true);
    if (conversation) {
      try {
        await edit({ ...conversation, title: value.title });
        dispatch(setTitle(value.title));
        onClose();
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const initConversation = async (id: string) => {
      const res = await conversationService.getConversation(id);
      setConversation(res);
      reset({ title: res.title || '' });
    };
    if (selected?.id) {
      initConversation(selected.id);
    }
  }, [reset, selected?.id]);

  return (
    <Dialog
      title={<Trans i18nKey="COMMON.EDIT" />}
      onClose={onClose}
      actions={
        <Button customTestId="confirm-dialog-confirm" onClick={handleSubmit(onSubmit)} loading={loading}>
          <Trans i18nKey="COMMON.SAVE" />
        </Button>
      }
      {...props}
    >
      <Form onSubmit={handleSubmit(onSubmit)} control={control} placement="top-left">
        <ControlledTextField control={control} name="title" label={<Trans i18nKey="CHAT.NAME" />} fullWidth />
      </Form>
    </Dialog>
  );
};

export default EditDialog;
