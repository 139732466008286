import React from 'react';
import { RouteConfigurationModel } from 'models';
import { Role } from 'config/roles';

import SettingsIcon from '@mui/icons-material/Settings';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

export const routes: RouteConfigurationModel[] = [
  {
    icon: <ChatBubbleIcon />,
    allowedFor: [Role.ADMIN, Role.OFFICE, Role.USER],
    path: 'chat',
    link: '/chat',
    title: 'CHAT.TITLE',
    noDisplay: true,
  },
  {
    icon: <ChatBubbleIcon />,
    allowedFor: [Role.ADMIN, Role.OFFICE, Role.USER],
    path: 'chat/:chatId',
    link: '/chat',
    title: 'CHAT.TITLE',
    noDisplay: true,
  },
  {
    icon: <PsychologyIcon />,
    allowedFor: [Role.ADMIN],
    path: 'knowledge',
    link: '/knowledge',
    title: 'KNOWLEDGE.TITLE',
  },
  {
    icon: <SettingsIcon />,
    allowedFor: [Role.ADMIN],
    path: 'prompts',
    link: '/prompts',
    title: 'PROMPTS.TITLE',
  },
];
