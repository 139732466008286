import React, { FC, useEffect, KeyboardEvent, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useSelector } from 'react-redux';

import { IconButton, InputAdornment, Box, Theme, Typography } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';

import Yup from 'utils/yup';
import { settings } from 'config/settings';
import { Button, ControlledTextField, Form } from 'components';
import { selectIsLoading } from 'store/chat';
import { ChatInputModel } from 'models';
import { mergeSx } from 'utils/styles';

const MAX_ROWS = 4;

const styles = {
  inputContainer: {
    display: 'flex',
    m: 2,
  },
  send: {
    ml: 1,
    mt: 'auto',
    mb: 'auto',
    height: (theme: Theme) => theme.spacing(7),
  },
  record: {
    color: 'red',
  },
  input: {
    '& .MuiInputBase-input': {
      '::-webkit-scrollbar': {
        width: 8,
      },

      '::-webkit-scrollbar-track': {
        backgroundColor: 'grey.100',
        borderRadius: 2,
      },

      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'grey.400',
        borderRadius: 2,
      },
    },
  },
  adornment: {
    width: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputCharacterDisplay: {
    position: 'absolute',
    bottom: 3,
    right: 5,
  },
  inputCharacterDisplayError: {
    color: 'error.main',
  },
};

interface ChatInputProps {
  send: (text: string) => Promise<void>;
}

const ChatInput: FC<ChatInputProps> = ({ send }) => {
  const { chatId } = useParams();
  const isLoading = useSelector(selectIsLoading);

  const [text, setText] = useState('');

  const initialValues: ChatInputModel = { text: '' };

  const validationSchema = Yup.object().shape({
    text: Yup.string().max(settings.MAX_CHARACTER),
  });

  const { handleSubmit, control, reset, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const onSubmit: SubmitHandler<ChatInputModel> = async (values: ChatInputModel) => {
    if (values?.text) {
      await send(values.text);
      reset();
    }
  };

  useEffect(() => {
    reset();
  }, [chatId, reset]);

  const value = useWatch({ control, name: 'text' });

  const { transcript, finalTranscript, resetTranscript, listening, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  const isRecording = listening ? styles.record : undefined;

  const onClickMic = listening
    ? () => SpeechRecognition.stopListening()
    : () => {
        setText(value || '');
        SpeechRecognition.startListening();
      };

  const counterStyle = (value || '').length > settings.MAX_CHARACTER ? styles.inputCharacterDisplayError : undefined;

  const endAdornment = (
    <>
      {browserSupportsSpeechRecognition ? (
        <InputAdornment position="end">
          <Box sx={styles.adornment}>
            <IconButton sx={isRecording} onClick={onClickMic}>
              <MicIcon />
            </IconButton>
            {(value || '').length > settings.SHOW_CHARACTER_DISPLAY && (
              <Typography sx={mergeSx(styles.inputCharacterDisplay, counterStyle)} variant="caption">
                {`${(value || '').length}/${settings.MAX_CHARACTER}`}
              </Typography>
            )}
          </Box>
        </InputAdornment>
      ) : null}
    </>
  );

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();

      if (!isLoading && value?.trim()) {
        handleSubmit(onSubmit)();
      }
    }
  };

  useEffect(() => {
    if (transcript) {
      setValue('text', `${text} ${transcript}`);
    }
  }, [transcript, text, setValue]);

  useEffect(() => {
    if (finalTranscript) {
      setValue('text', `${text} ${finalTranscript}`);
      resetTranscript();
    }
  }, [finalTranscript, text, setValue, resetTranscript]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} control={control} placement="top-left">
      <Box sx={styles.inputContainer} data-testid="chat-input-container">
        <ControlledTextField
          control={control}
          name="text"
          data-testid="chat-input"
          sx={styles.input}
          fullWidth
          autoComplete="off"
          multiline
          showEmpty={false}
          maxRows={MAX_ROWS}
          margin={'none'}
          onKeyDown={handleKeyPress}
          InputProps={{
            endAdornment,
          }}
        />
        <Button sx={styles.send} onClick={handleSubmit(onSubmit)} loading={isLoading} disabled={!value?.trim()}>
          <SendIcon />
        </Button>
      </Box>
    </Form>
  );
};

export default ChatInput;
