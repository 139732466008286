import React from 'react';
import { FieldValues, useController } from 'react-hook-form';

import { Typography } from '@mui/material';

import { ErrorMessage, FileUpload, FileUploadProps } from 'components';
import { FieldProps } from 'models';
import { arrayify } from 'utils/arrays';

interface RHCFileUploadFieldProps<T extends FieldValues> extends FileUploadProps, FieldProps<T> {
  showError?: boolean;
}

export const FileUploadField = <T extends FieldValues>({
  showError = true,
  name,
  control,
  rules,
  ...props
}: RHCFileUploadFieldProps<T>) => {
  const {
    field: { value: fieldValue, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const value = fieldValue ? arrayify(fieldValue) : null;

  return (
    <FileUpload
      valueDisplayNode={arrayify(value).map((val: File) => (
        <Typography key={val.name}>{val.name}</Typography>
      ))}
      errorNode={<ErrorMessage show={showError} message={error?.message} />}
      onDropAccepted={(files, _) => onChange(files)}
      {...props}
    />
  );
};
