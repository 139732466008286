import React, { HTMLAttributes, ReactNode, SyntheticEvent, useCallback, useMemo } from 'react';
import { FieldValues, Path, PathValue, useController } from 'react-hook-form';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { TextFieldProps, TextField, Autocomplete as MuiAutocomplete } from '@mui/material';

import { FieldProps } from 'models';
import { ErrorMessage } from 'components';
import { mergeSx } from 'utils/styles';
import { Env } from 'config/env';

export interface AutoCompleteProps<T extends FieldValues> extends FieldProps<T> {
  /**
   * If true, value must be an array and the menu will support multiple selections.
   */
  multiple?: boolean;
  /**
   * Array of options.
   */
  options: PathValue<T, Path<T>>[];
  /**
   * The key used for the value property in the options.
   */
  valueKey?: string;
  /**
   * The key used for displaying the option in the field.
   */
  displayKey?: string;
  /**
   * If set to ```true```, an error message is going to be visible under the field, in case of an error.
   */
  showError?: boolean;
  /**
   * If ```true```, the component becomes readonly. It is also supported for multiple tags where the tag cannot be deleted.
   */
  readOnly?: boolean;
  /**
   * Text to display when there are no options.
   */
  noOptionsText?: ReactNode;
  label: ReactNode;
  /**
   * If ```true```, the input can't be cleared.
   */
  disableClearable?: boolean;
}

const styles = {
  selectedItems: {
    '.MuiChip-root': {
      backgroundColor: 'primary.main',
      color: 'dark.contrastText',
    },
  },
};

export function Autocomplete<T extends FieldValues>({
  multiple = false,
  name,
  control,
  rules,
  fullWidth,
  showError = true,
  readOnly,
  valueKey = 'value',
  variant = Env.DEFAULT_INPUT_VARIANT,
  options,
  displayKey = 'label',
  noOptionsText,
  disableClearable,
  disabled,
  sx,
  ...props
}: AutoCompleteProps<T> & TextFieldProps) {
  const {
    field: { value: fieldValue, onChange, onBlur, ref: inputRef },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const handleChangeSingle = (e: SyntheticEvent, value: T | null) => {
    if (value === null) {
      onChange(value);
      return;
    }
    const selectedValue = options.find((option) => get(option, displayKey) === get(value, displayKey)) || null;

    onChange(selectedValue);
  };

  const handleChangeMulti = (e: SyntheticEvent, value: T[]) => {
    onChange(intersectionWith(options, value, isEqual));
  };

  const checkEqualToValue = useCallback(
    (optionEqual: T, valueEqual: T) => {
      if (!valueEqual || !optionEqual) {
        return false;
      }
      return get(optionEqual, valueKey) === get(valueEqual, valueKey);
    },
    [valueKey]
  );

  const renderList = useCallback(
    (propsRender: HTMLAttributes<HTMLLIElement>, option: T) => <li {...propsRender}>{get(option, displayKey)}</li>,
    [displayKey]
  );

  const renderTextfield = useCallback(
    (params: TextFieldProps) => (
      <>
        <TextField
          {...props}
          {...params}
          inputRef={inputRef}
          onBlur={onBlur}
          sx={mergeSx(multiple ? styles.selectedItems : null)}
          fullWidth={fullWidth}
          error={Boolean(error?.message?.length)}
          variant={variant}
        />
        <ErrorMessage show={showError} message={error?.message} />
      </>
    ),
    [error?.message, fullWidth, inputRef, multiple, onBlur, props, showError, variant]
  );

  const commonProps = useMemo(
    () => ({
      fullWidth,
      noOptionsText,
      options,
      readOnly,
      disabled,
      isOptionEqualToValue: (optionEqual: T, valueEqual: T) => checkEqualToValue(optionEqual, valueEqual),
      getOptionLabel: (option: T) => get(option, displayKey),
      renderOption: renderList,
      renderInput: renderTextfield,
      disableClearable,
      sx,
    }),
    [
      checkEqualToValue,
      disableClearable,
      displayKey,
      disabled,
      fullWidth,
      noOptionsText,
      options,
      readOnly,
      renderList,
      renderTextfield,
      sx,
    ]
  );

  const singleEmptyValue = isEmpty(fieldValue) ? null : fieldValue;
  const multiEmptyValue = isEmpty(fieldValue) ? [] : fieldValue;

  return !multiple ? (
    <MuiAutocomplete onChange={handleChangeSingle} value={singleEmptyValue} {...commonProps} />
  ) : (
    <MuiAutocomplete onChange={handleChangeMulti} value={multiEmptyValue} multiple limitTags={3} {...commonProps} />
  );
}
