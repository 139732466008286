import React, { FC, ReactElement, memo } from 'react';

import MuiTableRow, { TableRowProps as MuiTableRowProps } from '@mui/material/TableRow';

import { TableCell, TableLinkProps } from './TableCell';

export interface TableRowProps extends MuiTableRowProps, TableLinkProps {}

export const TableRow: FC<TableRowProps> = memo(({ children, link, to, replace, ...props }) => {
  return (
    <MuiTableRow {...props}>
      {children
        ? React.Children.map(children, (child) => {
            if (React.isValidElement(child) && child.type === TableCell) {
              return React.cloneElement(child as ReactElement, {
                link: child.props.link ? child.props.link : link,
                to,
                replace,
              });
            }

            return child;
          })
        : null}
    </MuiTableRow>
  );
});

export default TableRow;
