import { useQuery } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel } from 'models';
import { userService } from 'services';

type UserQueryContexts = QueryContextFromKeys<typeof keys>;

const keys = {
  all: [{ scope: 'users' }] as const,
  lists: () => [{ ...keys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...keys.lists()[0], params }] as const,
};

const fetchUsersList = async ({ queryKey: [{ params }] }: UserQueryContexts['list']) => userService.getUsers(params);

export const useUsersList = (params?: QueryParamModel | null) => useQuery(keys.list(params), fetchUsersList);
