import { combineReducers, configureStore } from '@reduxjs/toolkit';

/* GENERATORS_IMPORT_SLICE - DO NOT REMOVE! */
import { commonReducer } from './common';
import { profileReducer } from './profile';
import { authReducer } from './auth';
import { chatReducer } from './chat';

const reducerFallback = () => null;

export const rootReducer = combineReducers({
  /* GENERATORS_ADD_SLICE - DO NOT REMOVE! */
  common: commonReducer || reducerFallback,
  profile: profileReducer || reducerFallback,
  auth: authReducer || reducerFallback,
  chat: chatReducer || reducerFallback,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;

declare global {
  type ApplicationState = ReturnType<typeof rootReducer>;

  type AppDispatch = typeof store.dispatch;

  type GetState = () => ApplicationState;
}

declare module 'react-redux' {
  export function useDispatch<TDispatch = AppDispatch>(): TDispatch;
}
