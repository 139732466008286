import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { PrivateRoute, SideBar } from 'components';
import { routes } from 'config/routes';
import { selectSideBarState, toggleMobileSidebar, toggleSidebar } from 'store/common';
import { useAppDispatch } from 'utils/useAppDispatch';
import { mergeSx } from 'utils/styles';

export const styles = {
  mainContent: (theme: Theme) => ({
    transition: {
      md: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  }),
  mainContentShift: (theme: Theme) => ({
    marginLeft: {
      md: `${theme.mixins.drawer.width}px`,
    },
    transition: {
      md: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
};

export function PrivateLayout() {
  const dispatch = useAppDispatch();

  const sideBar = useSelector(selectSideBarState);

  const onSidebarToggle = () => {
    dispatch(toggleSidebar());
  };

  const onMobileSidebarToggle = () => {
    dispatch(toggleMobileSidebar());
  };

  return (
    <PrivateRoute>
      <SideBar
        open={sideBar.open}
        mobileOpen={sideBar.mobileOpen}
        toggleSidebar={onSidebarToggle}
        toggleMobileSidebar={onMobileSidebarToggle}
        pages={routes}
      />
      <Box sx={mergeSx(styles.mainContent, sideBar.open ? styles.mainContentShift : null)}>
        <Outlet />
      </Box>
    </PrivateRoute>
  );
}
