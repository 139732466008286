import React, { FC, useMemo, useCallback } from 'react';
import { Trans } from 'react-i18next';

import DeleteIcon from '@mui/icons-material/Delete';

import { QueryParamModel, KnowledgeModel, KnowledgeQueryModel } from 'models';
import { Table, useDialogState, DeleteDialog, TableProps } from 'components';
import { Role } from 'config/roles';
import { useDeleteKnowledge } from 'features/knowledges';
import KnowledgesTableUrlCell from './KnowledgesTableUrlCell';

interface KnowledgesTableProps extends Omit<TableProps<KnowledgeModel>, 'columns'> {
  isLoading: boolean;
  knowledges?: KnowledgeQueryModel;
  params: QueryParamModel;
}

const nameHeader = () => <Trans i18nKey="KNOWLEDGE.NAME" />;
const accessGroupHeader = () => <Trans i18nKey="KNOWLEDGE.ACCESS_GROUP" />;
const accessGroupAccessor = (item: KnowledgeModel) => <Trans i18nKey={`ACCESS_GROUP.${item.accessGroup}`} />;
const urlHeader = () => <Trans i18nKey="KNOWLEDGE.URL" />;
const urlAccessor = (item: KnowledgeModel) => <KnowledgesTableUrlCell item={item} />;

const tableColumns = [
  {
    key: 'name',
    field: 'name',
    header: nameHeader,
    accessor: 'name',
    sortable: true,
    noWrap: true,
  },
  {
    key: 'url',
    field: 'url',
    header: urlHeader,
    accessor: urlAccessor,
    sortable: true,
    noWrap: true,
  },
  {
    key: 'accessGroup',
    field: 'accessGroup',
    header: accessGroupHeader,
    accessor: accessGroupAccessor,
    sortable: true,
  },
];

const KnowledgesTable: FC<KnowledgesTableProps> = ({ isLoading, knowledges, params, ...props }) => {
  const {
    isOpen: isDeleteOpen,
    openDialog: openDelete,
    closeDialog: closeDelete,
    selected: selectedDelete,
  } = useDialogState<KnowledgeModel>();

  const { mutate: deleteKnowledge, isLoading: isLoadingDelete } = useDeleteKnowledge();

  const onDelete = () => {
    if (selectedDelete?.id) {
      deleteKnowledge(selectedDelete.id);
    }
  };

  const deleteAction = useCallback((item: KnowledgeModel) => openDelete(item), [openDelete]);

  const tableActions = useMemo(
    () => [
      {
        key: 'delete',
        allowedFor: [Role.ADMIN],
        icon: DeleteIcon,
        action: deleteAction,
      },
    ],
    [deleteAction]
  );

  return (
    <>
      <Table
        list={knowledges?.data}
        total={knowledges?.total}
        loading={isLoading}
        params={params}
        {...props}
        columns={tableColumns}
        actions={tableActions}
        isLoadingDelete={isLoadingDelete}
      />

      <DeleteDialog
        open={isDeleteOpen}
        selected={selectedDelete}
        onClose={closeDelete}
        onSubmit={onDelete}
        deleteTitleAccessor="name"
      />
    </>
  );
};

export default KnowledgesTable;
