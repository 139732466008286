import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { QueryContextFromKeys, QueryParamModel, PromptsModel } from 'models';
import { promptsService } from 'services';

type UserQueryContexts = QueryContextFromKeys<typeof keys>;

const keys = {
  all: [{ scope: 'prompts' }] as const,
  lists: () => [{ ...keys.all[0], entity: 'list' }] as const,
  list: (params?: QueryParamModel | null) => [{ ...keys.lists()[0], params }] as const,
};

const fetchPromptsList = async ({ queryKey: [{ params }] }: UserQueryContexts['list']) =>
  promptsService.getPrompts(params);

export const usePromptsList = (params?: QueryParamModel | null) => useQuery(keys.list(params), fetchPromptsList);

export const useEditPrompt = () => {
  const queryClient = useQueryClient();

  return useMutation((data: PromptsModel) => promptsService.editPrompts(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.lists() });
    },
  });
};

export const useRestorePrompt = () => {
  const queryClient = useQueryClient();

  return useMutation((key: string) => promptsService.editRestore(key), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.lists() });
    },
  });
};
