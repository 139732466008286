import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, IconButton, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';

import { ProfileImage } from 'components';
import { ChatInputModel, DiscourseModel } from 'models';

import { useUsersList } from 'features/users';
import { selectIsLoading } from 'store/chat';

import QuestionInputForm from './QuestionInputForm';

const styles = {
  question: {
    m: 2,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
  },
  text: {
    ml: 2,
    width: '100%',
    '& pre': {
      margin: 0,
      textWrap: 'wrap',
      overflowWrap: 'break-word',
    },
  },
  actions: {
    marginLeft: 'auto',
  },
  input: {
    '& .MuiInputBase-input': {
      '::-webkit-scrollbar': {
        width: 8,
      },

      '::-webkit-scrollbar-track': {
        backgroundColor: 'grey.100',
        borderRadius: 2,
      },

      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'grey.400',
        borderRadius: 2,
      },
    },
  },
  bottomContainer: {
    ml: 'auto',
    display: 'flex',
    mr: 5,
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
  },
};

interface QuestionProps {
  message: DiscourseModel;
  next: () => void;
  previous: () => void;
  active?: number;
  length?: number;
  editQuestion: (discourseId: string | null, text: string) => void;
  disabled?: boolean;
}

const Question: FC<QuestionProps> = ({ message, next, previous, editQuestion, active, length, disabled }) => {
  const isLoading = useSelector(selectIsLoading);
  const [showEdit, setShowEdit] = useState<boolean>();

  const { data: users } = useUsersList();
  const profilePicture = users?.find((user) => user.id === message.createdBy)?.picture;

  const openEditModel = () => {
    setShowEdit(true);
  };

  const handleSubmitEdit = ({ text }: ChatInputModel) => {
    if (text) {
      editQuestion(message.parentId, text);
      setShowEdit(false);
    }
  };

  const onCloseEdit = () => {
    setShowEdit(false);
  };

  return (
    <Box sx={styles.question} data-testid="question">
      <Box sx={styles.container}>
        <ProfileImage src={profilePicture || undefined} />
        <Box sx={styles.text} mt={showEdit ? 0 : 2}>
          {showEdit ? (
            <QuestionInputForm onClose={onCloseEdit} onSubmit={handleSubmitEdit} initialText={message?.text} />
          ) : (
            <pre>
              <Typography>{message.text}</Typography>
            </pre>
          )}
        </Box>
        {!showEdit && !disabled && (
          <Box sx={styles.actions}>
            <IconButton onClick={openEditModel} disabled={isLoading}>
              <EditIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box sx={styles.bottomContainer}>
        {active !== undefined && length && length > 1 ? (
          <>
            <IconButton onClick={previous} disabled={isLoading}>
              <ChevronLeftIcon />
            </IconButton>
            <Box sx={styles.pagination}>{`${active + 1} / ${length}`}</Box>
            <IconButton onClick={next} disabled={isLoading}>
              <ChevronRightIcon />
            </IconButton>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default Question;
