import React, { FC } from 'react';

import FormHelperText from '@mui/material/FormHelperText';

import { SxProp } from 'models';
import { mergeSx } from 'utils/styles';

interface ErrorMessageProps extends SxProp {
  /**
   * Determines if the error message is visible or not.
   */
  show?: boolean;
  /**
   * The variant to use.
   */
  variant?: 'standard' | 'outlined' | 'filled';
  /**
   * The class(es) (in case if you prefer classes over sx).
   */
  className?: string;
  /**
   * The error message to display.
   * If not provided, an empty space is going to be displayed.
   */
  message?: string | null;

  showEmpty?: boolean;
}

const styles = {
  root: {
    marginTop: -0.5,
  },
};

export const ErrorMessage: FC<ErrorMessageProps> = ({
  variant,
  sx,
  className,
  message,
  show = false,
  showEmpty = true,
}) => {
  const empty = showEmpty ? <span data-testid="error-message-empty-state">&nbsp;</span> : '';
  return show ? (
    <FormHelperText variant={variant} error className={className} sx={mergeSx(styles.root, sx)} role="error">
      {message ?? empty}
    </FormHelperText>
  ) : null;
};
