import React, { useCallback, ReactElement, ReactNode, ReactFragment, ReactPortal } from 'react';

import { TooltipProps } from '@mui/material';

import { ConditionalWrapper, Tooltip } from 'components';

interface TooltipContentProps {
  showTooltip?: boolean;
  tooltipDataTestid?: string;
  title: ReactFragment | ReactNode | ReactPortal;
  children: ReactNode;
  placement?: TooltipProps['placement'];
}

export const TooltipContent = ({
  showTooltip,
  tooltipDataTestid = 'tooltip-content',
  title,
  children,
  placement,
}: TooltipContentProps) => {
  const tooltipWrapper = useCallback(
    (tooltipChildren: ReactElement) => (
      <Tooltip data-testid={tooltipDataTestid} title={title} placement={placement}>
        {tooltipChildren}
      </Tooltip>
    ),
    [title, tooltipDataTestid, placement]
  );

  return (
    <ConditionalWrapper condition={Boolean(showTooltip)} wrapper={tooltipWrapper}>
      {children}
    </ConditionalWrapper>
  );
};
