import React, { FC, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { Box, Paper, Theme } from '@mui/material';

import { Env } from 'config/env';
import { Header } from 'components';
import { discoursesService, conversationService } from 'services';
import { useBeforeUnload } from 'components/util/useBeforeUnload';
import {
  selectLastAnswerId,
  selectTitle,
  setIsLoadingOff,
  setIsLoadingOn,
  setSSETitle,
  setTitle,
  selectIsLoading,
} from 'store/chat';
import { ConversationModel, DiscourseModel, DiscourseRole, DiscoursesResponse, FeedbackModel } from 'models';
import { removeOneItemFromArray } from 'utils/arrays';
import { useAppDispatch } from 'utils/useAppDispatch';
import { selectProfile } from 'store/profile';
import { mergeSx } from 'utils/styles';
import { keys } from 'features/conversations';
import Chat from './Chat';
import ChatInput from './ChatInput';
import Logo from './Logo';

const DEFAULT_TITLE = 'New Chat';

const styles = {
  root: {
    ml: 3,
    mr: 3,
  },
  container: {
    width: '100%',
    height: (theme: Theme) => ({
      xs: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(6)})`,
      sm: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(8)})`,
    }),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 3,
    borderTop: '3px solid white',
    mt: {
      xs: 10,
      sm: 12,
    },
  },
  chatContainer: {
    '::-webkit-scrollbar': {
      width: 8,
    },

    '::-webkit-scrollbar-track': {
      backgroundColor: 'common.white',
      marginTop: 2,
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'grey.400',
      borderRadius: 2,
    },
    overflow: 'auto',
    height: '100%',
  },
  disabledChatContainer: {
    '::-webkit-scrollbar-track': {
      marginBottom: 2,
    },
  },
  disabledContainer: {
    borderBottom: '3px solid white',
  },
  hintContainer: { height: '100%', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' },
};

const concatenateTexts = (texts: string[], size: number) => texts.slice(0, size).join('');

const updateMessageText = (message: DiscourseModel, texts: string[], size: number) =>
  `${message.text || ''}${concatenateTexts(texts, size)}`;

const updateMessages = (messages: DiscourseModel[], selected: DiscourseModel, texts: string[], size: number) => [
  ...removeOneItemFromArray(messages, selected, 'id'),
  {
    ...selected,
    text: `${updateMessageText(selected, texts, size)}`,
  },
];

let eventSource: EventSource | null = null;

const ChatPage: FC = () => {
  const { chatId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const isLoading = useSelector(selectIsLoading);
  const lastAnswerId = useSelector(selectLastAnswerId);
  const profile = useSelector(selectProfile);
  const title = useSelector(selectTitle);

  const [conversation, setConversation] = useState<ConversationModel | undefined>();
  const [messages, setMessages] = useState<DiscourseModel[]>([]);
  const [isFirstQuestion, setIsFirstQuestion] = useState<boolean>(true);
  const [puffer, setPuffer] = useState<{ id: string; text: string }[]>([]);
  const [titlePuffer, setTitlePuffer] = useState<string[]>([]);
  const [isSseActive, setIsSseActive] = useState<boolean>(false);

  const messagesRef = useRef<HTMLDivElement>();

  const disabled = useMemo(() => conversation && conversation?.ownerId !== profile?.id, [conversation, profile?.id]);

  const unloadHandler = (e: Event) => {
    eventSource?.close();
  };

  useBeforeUnload({ showDialog: isLoading, unloadHandler });

  useEffect(() => {
    if (puffer.length > 0) {
      setPuffer((puff) => {
        const size = puffer.length > Env.CHUNK_LOCAL_SIZE_LENGTH ? Env.CHUNK_LOCAL_SIZE_LENGTH : puffer.length;
        setMessages((prev) => {
          const selected = prev.find((item) => item.id === puff[0].id);
          if (selected) {
            return updateMessages(
              prev,
              selected,
              puff.map((item) => item.text),
              size
            );
          }
          return prev;
        });
        return puff.slice(size);
      });
    }
  }, [puffer, dispatch, isSseActive]);

  useEffect(() => {
    if (!isSseActive && puffer.length === 0) {
      setTimeout(() => dispatch(setIsLoadingOff()), 50);
    }
  }, [puffer, dispatch, isSseActive]);

  useEffect(() => {
    if (titlePuffer.length > 0) {
      const size = titlePuffer.length > Env.CHUNK_LOCAL_SIZE_LENGTH ? Env.CHUNK_LOCAL_SIZE_LENGTH : titlePuffer.length;
      setTitlePuffer((puff) => {
        dispatch(setSSETitle(concatenateTexts(puff, size)));
        return puff.slice(size);
      });
    }
  }, [dispatch, titlePuffer]);

  const handleSSE = useCallback((link: string, id: string, callBack?: () => void) => {
    eventSource = new EventSource(link, { withCredentials: true });
    setIsSseActive(true);
    eventSource.onmessage = (e) => {
      setPuffer((puff) => [
        ...puff,
        ...JSON.parse(e.data)
          .textChunk.split('')
          .map((dataText: string) => ({ id, text: dataText })),
      ]);
    };

    eventSource.onerror = (e) => {
      eventSource?.close();
      setIsSseActive(false);
      if (callBack) {
        callBack();
      }
    };
  }, []);

  const handleStream = useCallback(
    (link: string, discourseResponse: DiscoursesResponse) => {
      discourseResponse.discourses.forEach((discourse) => {
        setMessages((prev) => [...removeOneItemFromArray(prev, discourse, 'id'), discourse]);
      });

      handleSSE(link, discourseResponse.discourseId);
    },
    [handleSSE]
  );

  const createTitle = useCallback(
    (id: string) => {
      const eventSourceTitle = new EventSource(`${process.env.REACT_APP_API_URL}/conversations/${id}/title`, {
        withCredentials: true,
      });

      dispatch(setTitle(''));

      eventSourceTitle.onmessage = (e) => {
        setTitlePuffer((prev) => [...prev, ...JSON.parse(e.data).textChunk.split('')]);
      };

      eventSourceTitle.onerror = (e) => {
        eventSourceTitle.close();
        queryClient.invalidateQueries({ queryKey: keys.lists() });
      };
    },
    [dispatch, queryClient]
  );

  const createDiscourse = async (id: string, text: string) => {
    const lastId = lastAnswerId || (messages.length > 0 ? messages[messages.length - 1].id : undefined);
    const res = await discoursesService.createDiscourses(id, text, lastId);
    handleStream(`${process.env.REACT_APP_API_URL}/conversations/${id}/discourses/${res.discourseId}/stream`, res);
  };

  const regenerate = useCallback(
    async (discourseId: string) => {
      if (conversation?.id) {
        dispatch(setIsLoadingOn());
        const res = await discoursesService.regenerateAnswer(conversation.id, discourseId);
        handleStream(
          `${process.env.REACT_APP_API_URL}/conversations/${conversation.id}/discourses/${res.discourseId}/stream/regen`,
          res
        );
      }
    },
    [conversation?.id, dispatch, handleStream]
  );

  const editQuestion = useCallback(
    async (discourseId: string | null, question: string) => {
      if (conversation?.id) {
        dispatch(setIsLoadingOn());
        const res = await discoursesService.createDiscourses(conversation?.id, question, discourseId);
        handleStream(
          `${process.env.REACT_APP_API_URL}/conversations/${conversation.id}/discourses/${res.discourseId}/stream`,
          res
        );
      }
    },
    [conversation?.id, dispatch, handleStream]
  );

  const send = async (text: string) => {
    dispatch(setIsLoadingOn());
    if (isFirstQuestion) {
      const res = await conversationService.createConversation({
        title: DEFAULT_TITLE,
      });
      setConversation(res);
      setIsFirstQuestion(false);
      await discoursesService.createDiscourses(res.id, text, null);
      queryClient.invalidateQueries({ queryKey: keys.lists() });
      navigate(`${res.id}`, { replace: true });
    } else if (conversation?.id) {
      await createDiscourse(conversation.id, text);
    }
  };

  const submitFeedback = useCallback(async (id: string, feedback: FeedbackModel) => {
    const res = await discoursesService.createFeedback(id, feedback);
    setMessages((prev) => {
      const indexUpdate = prev.findIndex((item) => item.id === res.id);
      prev[indexUpdate] = res;
      return prev;
    });
  }, []);

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTo({ top: messagesRef.current.scrollHeight });
    }
  }, [messages]);

  useEffect(() => {
    eventSource?.close();
    setIsSseActive(false);

    const fetchData = async (id: string) => {
      const conversationResponse = await conversationService.getConversation(id);
      setConversation(conversationResponse);
      dispatch(setTitle(conversationResponse.title || DEFAULT_TITLE));
      setIsFirstQuestion(false);
      const discoursesResponse = await conversationService.getDiscourses(conversationResponse.id);
      const discourses = discoursesResponse.data;
      setMessages(discourses);

      if (discourses.length === 2) {
        const answer = discourses.find((discourse) => discourse.role === DiscourseRole.ASSISTANT);
        if (answer?.text === null) {
          dispatch(setIsLoadingOn());
          handleSSE(
            `${process.env.REACT_APP_API_URL}/conversations/${conversationResponse.id}/discourses/${answer.id}/stream`,
            answer.id,
            () => createTitle(conversationResponse.id)
          );
        }
      }
    };

    if (chatId) {
      fetchData(chatId);
    } else {
      setConversation(undefined);
      setMessages([]);
      setIsFirstQuestion(true);
      dispatch(setIsLoadingOff());
      dispatch(setTitle(DEFAULT_TITLE));
    }
  }, [chatId, createTitle, handleSSE, dispatch]);

  return (
    <Box sx={styles.root}>
      <Header title={title} />
      <Paper sx={mergeSx(styles.container, disabled ? styles.disabledContainer : null)}>
        {!messages?.length && <Logo />}
        <Box ref={messagesRef} sx={mergeSx(styles.chatContainer, disabled ? styles.disabledChatContainer : null)}>
          <Chat
            messages={messages}
            regenerate={regenerate}
            editQuestion={editQuestion}
            submitFeedback={submitFeedback}
            disabled={disabled}
          />
        </Box>
        {!disabled && <ChatInput send={send} />}
      </Paper>
    </Box>
  );
};

export default ChatPage;
