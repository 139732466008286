import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Page, useTableQueryParams } from 'components';
import { SortDirection } from 'utils/arrays';
import { usePromptsList } from 'features/prompts';
import PromptsTable from './PromptsTable';

const PromptsPage: FC = () => {
  const { t } = useTranslation();
  const title = `${t('PROMPTS.TITLE')}`;

  const { onPageChange, onRowsPerPageChange, onSort, params } = useTableQueryParams({
    sortBy: 'key',
    orderBy: SortDirection.ASC,
  });

  const { data: prompts, isLoading: isLoadingList } = usePromptsList({
    ...params,
  });

  return (
    <Page title={title}>
      <PromptsTable
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onSort={onSort}
        params={params}
        isLoading={isLoadingList}
        prompts={prompts}
      />
    </Page>
  );
};

export default PromptsPage;
