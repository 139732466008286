import React from 'react';
import { FieldValues, useController } from 'react-hook-form';

import { TextFieldProps as MuiTextFieldProps } from '@mui/material';

import { FieldProps } from 'models';
import { TextField, TextFieldProps } from './TextField';

interface ControlledTextFieldProps<T extends FieldValues> extends TextFieldProps, FieldProps<T> {}

// If the value of the form comes directly from an API call or an async function, it is recommended to use this Controlled version of TextField
export const ControlledTextField = <T extends FieldValues = FieldValues>({
  name,
  control,
  rules,
  ...props
}: ControlledTextFieldProps<T> & MuiTextFieldProps) => {
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });
  return (
    <TextField
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      ref={ref}
      errorMessage={error?.message}
      {...props}
    />
  );
};
