import { SourceHTMLAttributes } from 'react';
import orderBy from 'lodash/orderBy';

import { Breakpoint, Breakpoints } from '@mui/system';

import { Size } from 'models';
import { getSizes } from 'utils/image';

export type SourceType = Omit<
  SourceHTMLAttributes<HTMLSourceElement>,
  'media' | 'srcSet' | 'sizes' | 'height' | 'width'
> & {
  dimension?: { width: number; height: number };
  srcSet: string | undefined;
} & sizesType;

export type sizesType = { sizes?: string | Size[] };

interface SourceConfig extends SourceHTMLAttributes<HTMLSourceElement> {
  key: Breakpoint;
}

export type BreakpointSourceConfig = Record<Breakpoint, SourceType>;

export const configureSourcesConfig = (breakpoints: Breakpoints['values']) => {
  const orderedBreakpoints = getMinWidthDescBreakpoints(breakpoints);

  return (sources: Partial<BreakpointSourceConfig>) => {
    return orderedBreakpoints.reduce<SourceConfig[]>((acc, { name, minWidth }) => {
      const source = sources[name];

      if (source) {
        acc.push(getSourceAttributes(source, { name, minWidth }));
      }
      return acc;
    }, []);
  };
};

const getMinWidthDescBreakpoints = (breakpoints: Breakpoints['values']): { name: Breakpoint; minWidth: number }[] =>
  orderBy(
    Object.entries(breakpoints).map(([key, value]) => ({ name: key as Breakpoint, minWidth: value })),
    ['minWidth'],
    ['desc']
  );

const getSourceAttributes = (
  { dimension, sizes, ...source }: SourceType,
  { name, minWidth }: { name: Breakpoint; minWidth: number }
) => ({
  ...source,
  ...dimension,
  ...getKeyAndMedia({ name, minWidth }),
  sizes: getSizes(sizes),
});

const getKeyAndMedia = ({ name, minWidth }: { name: Breakpoint; minWidth: number }) => ({
  key: name,
  media: `(min-width: ${minWidth}px)`,
});
