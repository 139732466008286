import { StringifyOptions } from 'query-string';

export type InputVariant = 'outlined' | 'filled' | 'standard';
export type InputMargin = 'none' | 'dense' | 'normal';

const processEnv = {
  ...process.env,
  ...(window.__RUNTIME_ENV__ || {}),
};

export const Env = {
  API_REFRESH_TOKEN_KEY: 'API_REFRESH_TOKEN',
  API_TOKEN_KEY: 'API_TOKEN',
  QUERY_FORMAT: { arrayFormat: 'bracket' } as StringifyOptions,
  DEFAULT_INPUT_VARIANT: 'outlined' as InputVariant,
  DEFAULT_INPUT_MARGIN: 'dense' as InputMargin,
  REGEX_PASSWORD: /^.{8,}$/,
  MINIMUM_PASSWORD_LENGTH: 5,
  ROWS_PER_PAGE_OPTIONS: [10, 25, 50, 100],
  SEARCH_DELAY: 500,
  CHUNK_LOCAL_SIZE_LENGTH: 3,
  COOKIE_CONSENT_USED: true,
  TEST_ID_ATTRIBUTE_KEY: 'data-testid',
  PROFILE_IMAGE_MAX_SIZE: 5242880,
  ...processEnv,
  DEFAULT_IMG_QUALITY: 75,
  DEFAULT_IMG_SIZES: [600, 900, 1200, 1536, 1800, 2400, 3072],
};

// Be able to see the current configuration during development
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  console.log(Env);
}
