import React, { FC, useMemo, useCallback } from 'react';
import { Trans } from 'react-i18next';

import EditIcon from '@mui/icons-material/Edit';
import RestoreIcon from '@mui/icons-material/Restore';

import { QueryParamModel, PromptsModel, PromptsQueryModel } from 'models';
import { Table, useDialogState, TableProps } from 'components';
import { Role } from 'config/roles';
import { useEditPrompt, useRestorePrompt } from 'features/prompts';
import PromptsModal from './PromptsModal';

interface PromptsTableProps extends Omit<TableProps<PromptsModel>, 'columns'> {
  isLoading: boolean;
  prompts?: PromptsQueryModel;
  params: QueryParamModel;
}

const keyHeader = () => <Trans i18nKey="PROMPTS.KEY" />;
const valueHeader = () => <Trans i18nKey="PROMPTS.VALUE" />;

const tableColumns = [
  {
    key: 'key',
    field: 'key',
    header: keyHeader,
    accessor: 'key',
    sortable: true,
    noWrap: true,
  },
  {
    key: 'value',
    field: 'value',
    header: valueHeader,
    accessor: 'value',
    noWrap: true,
  },
];

const PromptsTable: FC<PromptsTableProps> = ({ isLoading, prompts, params, ...props }) => {
  const { isOpen, openDialog, closeDialog, selected } = useDialogState<PromptsModel>();

  const { mutateAsync: edit } = useEditPrompt();
  const { mutateAsync: restore } = useRestorePrompt();

  const editAction = useCallback((item: PromptsModel) => openDialog(item), [openDialog]);
  const restoreAction = useCallback(async (item: PromptsModel) => restore(item.key), [restore]);

  const tableActions = useMemo(
    () => [
      {
        key: 'edit',
        allowedFor: [Role.ADMIN],
        icon: EditIcon,
        action: editAction,
      },
      {
        key: 'restore',
        allowedFor: [Role.ADMIN],
        icon: RestoreIcon,
        action: restoreAction,
      },
    ],
    [editAction, restoreAction]
  );

  return (
    <>
      <Table
        list={prompts?.data}
        total={prompts?.total}
        loading={isLoading}
        params={params}
        {...props}
        columns={tableColumns}
        actions={tableActions}
      />
      <PromptsModal open={isOpen} onClose={closeDialog} edit={edit} prompt={selected} />
    </>
  );
};

export default PromptsTable;
