import React, { ReactNode, ComponentType, useCallback } from 'react';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';

import { AccessController, TooltipContent, DeleteIndicator } from 'components';
import { arrayify, removeEmpty } from 'utils/arrays';
import { AccessRestriction } from 'utils/auth';
import theme from 'theme';

import { TableRoot, BaseTableRootProps } from './components/TableRoot';
import { TableCell } from './components/TableCell';
import { TableRow } from './components/TableRow';
import { TableHeaderCell } from './components/TableHeaderCell';

export type AccessorFunction<T> = (item: T, index: number) => ReactNode;

export type Accessor<T> = string | AccessorFunction<T>;

export type KeyExtractor<T> = string | ((row: T, index: number) => string);

export interface Column<T> extends AccessRestriction {
  key: string;
  header?: () => ReactNode;
  tooltip?: Accessor<T> | ReactNode;
  hidden?: boolean;
}

export interface ColumnProps<T> extends Column<T> {
  accessor?: Accessor<T>;
  field?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  sortable?: boolean;
  noWrap?: boolean;
  padding?: 'checkbox' | 'none' | 'normal';
}

export interface ActionColumnProps<T> extends Column<T> {
  icon: ComponentType<SvgIconProps>;
  action?: (item: T, index: number) => unknown;
  disabled?: (item: T) => boolean;
}

export const isActionDisabled = <T,>(action: ActionColumnProps<T>, item: T) =>
  action.disabled ? action.disabled(item) : false;

export interface TableProps<T> extends BaseTableRootProps {
  /**
   * The list of items that is rendered in the table.
   */
  list?: T[] | T;
  /**
   * Set of columns.
   */
  columns: (ColumnProps<T> | null)[];
  /**
   * List of actions that the user can trigger for the list items.
   */
  actions?: (ActionColumnProps<T> | null)[];
  /**
   * Gets the keys for the rows.
   */
  keyField?: KeyExtractor<T>;
  isLoadingDelete?: boolean;
}

const styles = {
  deleteIndicator: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    width: '100%',
    top: theme.spacing(40),
  },
};

export const Table = <T extends Record<string, any>>({
  isLoadingDelete,
  columns,
  actions,
  list,
  keyField,
  ...props
}: TableProps<T>) => {
  const arrayList = arrayify(list);
  const validColumns = removeEmpty(columns);
  const validActions = removeEmpty(actions);

  const getFieldName = (column: ColumnProps<T>) => {
    if (!column.sortable) {
      return null;
    }

    return typeof column.accessor === 'string' ? column.accessor : column.field;
  };

  const getColumnHeader = (column: ColumnProps<T>) => (column.header ? column.header() : null);

  const getActionHeader = (action: ActionColumnProps<T>) => (action.header ? action.header() : null);

  return (
    <>
      {isLoadingDelete && (
        <Box sx={styles.deleteIndicator}>
          <DeleteIndicator />
        </Box>
      )}
      <TableRoot {...props} count={arrayList.length} isLoadingDelete={isLoadingDelete}>
        <TableHead>
          <TableRow>
            {validColumns.map((column) =>
              !column.hidden ? (
                <AccessController allowedFor={column.allowedFor} key={column.key}>
                  <TableHeaderCell
                    data-testid="table-column-header"
                    align={column.align}
                    padding={column.padding}
                    field={getFieldName(column)}
                  >
                    {getColumnHeader(column)}
                  </TableHeaderCell>
                </AccessController>
              ) : null
            )}
            {validActions.map((action) =>
              !action.hidden ? (
                <AccessController allowedFor={action.allowedFor} key={action.key}>
                  <TableHeaderCell data-testid="table-action-header" align="right" padding="checkbox">
                    {getActionHeader(action)}
                  </TableHeaderCell>
                </AccessController>
              ) : null
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {arrayList.map((item, index) => (
            <Row
              key={String(isFunction(keyField) ? keyField(item, index) : get(item, keyField || 'id'))}
              item={item}
              index={index}
              columns={removeEmpty(columns)}
              actions={removeEmpty(actions)}
            />
          ))}
        </TableBody>
      </TableRoot>
    </>
  );
};

export interface RowProps<T> {
  columns: ColumnProps<T>[];
  actions: ActionColumnProps<T>[];
  item: T;
  index: number;
}

export const Row = <T extends Record<string, any>>({ columns, actions, item, index }: RowProps<T>) => {
  const getValue = useCallback(
    (accessor?: Accessor<T> | ReactNode) => {
      if (!accessor) {
        return null;
      }

      if (typeof accessor === 'string') {
        return get(item, accessor);
      }

      if (isFunction(accessor)) {
        return accessor(item, index);
      }

      return accessor;
    },
    [item, index]
  );

  const onActionClick = useCallback(
    (action: ActionColumnProps<T>) => () => {
      if (action.action) {
        action.action(item, index);
      }
    },
    [item, index]
  );

  return (
    <TableRow data-testid="row">
      {columns.map((column) =>
        !column.hidden ? (
          <AccessController allowedFor={column.allowedFor} key={column.key}>
            <TableCell data-testid="row-column" align={column.align} padding={column.padding} noWrap={column.noWrap}>
              <TooltipContent
                tooltipDataTestid="row-column-tooltip"
                showTooltip={Boolean(column.tooltip)}
                title={getValue(column.tooltip)}
              >
                {getValue(column.accessor)}
              </TooltipContent>
            </TableCell>
          </AccessController>
        ) : null
      )}

      {actions.map((action) =>
        !action.hidden ? (
          <AccessController allowedFor={action.allowedFor} key={action.key}>
            <TableCell data-testid="row-action" align="right" padding="checkbox">
              <TooltipContent
                title={getValue(action.tooltip)}
                showTooltip={Boolean(action.tooltip)}
                tooltipDataTestid="row-action-tooltip"
              >
                <IconButton
                  data-testid="row-action-button"
                  onClick={onActionClick(action)}
                  size="large"
                  disabled={isActionDisabled(action, item)}
                >
                  <action.icon fontSize="small" />
                </IconButton>
              </TooltipContent>
            </TableCell>
          </AccessController>
        ) : null
      )}
    </TableRow>
  );
};
