import * as Yup from 'yup';
import i18n from 'i18n';
import { Env } from 'config/env';

declare module 'yup' {
  interface StringSchema {
    password(message?: string): Yup.StringSchema;
  }
}

Yup.setLocale({
  mixed: {
    required: () => i18n.t('ERRORS.FIELD_REQUIRED'),
    oneOf: () => i18n.t('ERRORS.FIELD_REQUIRED'),
    notType: (params: any) => {
      const errors: Record<string, string> = {
        number: i18n.t('ERRORS.TYPE_NUMBER'),
        date: i18n.t('ERRORS.INVALID_DATE'),
      };

      return errors[params.type] || i18n.t('ERRORS.WRONG_FIELD_TYPE');
    },
  },
  string: {
    email: () => i18n.t('ERRORS.EMAIL_INVALID'),
    min: ({ min }: any) => i18n.t('ERRORS.FIELD_MIN_LENGTH', { min }),
    max: ({ max }: any) => i18n.t('ERRORS.FIELD_MAX_LENGTH', { max }),
  },
});

Yup.addMethod(Yup.string, 'password', function password(this: Yup.StringSchema, message?: string) {
  return this.matches(
    Env.REGEX_PASSWORD,
    () => message || i18n.t('ERRORS.PASSWORD_INVALID', { min: Env.MINIMUM_PASSWORD_LENGTH })
  );
});

export default Yup;
