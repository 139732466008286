import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { Env } from 'config/env';
import { CookieConsent } from 'components';
import { selectIsLoggedIn } from 'store/auth';
import { parseRedirectLink } from 'utils/redirectLink';

interface AuthRouteProps {
  children: ReactNode;
}

export function AuthRoute({ children }: AuthRouteProps) {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const location = useLocation();

  return isLoggedIn ? (
    <Navigate to={parseRedirectLink(location) || '/'} />
  ) : (
    <>
      {children}
      {Env.COOKIE_CONSENT_USED && <CookieConsent backdrop />}
    </>
  );
}
