import { createTheme } from '@mui/material/styles';
import { theme as defaultTheme } from '@attrecto/apps-react-components';

import { Montserrat, MontserratSemiBold, MontserratBold } from './fonts';

const theme = createTheme(defaultTheme, {
  mixins: {
    drawer: {
      width: 350,
    },
    panel: {
      width: 320,
    },
    toolbar: {
      minHeight: 56,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: '"Montserrat","Helvetica","Arial",sans-serif',
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'visible',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        deleteIcon: {
          color: 'unset',
          '&:hover': {
            color: 'unset',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          paddingLeft: defaultTheme.spacing(3),
          paddingRight: defaultTheme.spacing(3),
        },
        outlined: {
          '&.Mui-disabled': {
            border: '1px solid',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
          ${Montserrat}
          ${MontserratSemiBold}
          ${MontserratBold}`,
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingLeft: defaultTheme.spacing(3),
          paddingRight: defaultTheme.spacing(3),
          paddingBottom: defaultTheme.spacing(2),
          paddingTop: defaultTheme.spacing(0),
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          paddingLeft: defaultTheme.spacing(2),
          paddingRight: defaultTheme.spacing(2),
          paddingTop: defaultTheme.spacing(1),
          paddingBottom: defaultTheme.spacing(1),
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          backgroundColor: defaultTheme.palette.common.white,
          paddingLeft: `${defaultTheme.spacing(1)}px!important`,
          paddingRight: `${defaultTheme.spacing(1)}px!important`,
        },
        formControl: {
          '& + .MuiInput-root': {
            marginTop: defaultTheme.spacing(2),
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: defaultTheme.spacing(2),
          borderRadius: defaultTheme.spacing(0.5),
        },
      },
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
  },
});

// Let the theme object be available quickly during development
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  console.log(theme);
}

export default theme;
