import React from 'react';

import { Box, CircularProgress } from '@mui/material';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import { red } from '@mui/material/colors';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 200,
    width: 68,
    height: 68,
    background: 'white',
  },
  progress: {
    color: red[500],
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: '100%',
    right: '100%',
    width: '100%',
    height: '100%',
  },
  show: {
    display: 'none',
  },
};

export const DeleteIndicator = () => {
  return (
    <Box sx={styles.container}>
      <AutoDeleteIcon />
      <CircularProgress size={68} sx={styles.progress} />
    </Box>
  );
};
