import React from 'react';
import get from 'lodash/get';

import { KnowledgeModel } from 'models';
import { OuterLink } from 'components';

interface SettingsTableUrlCellProps {
  item: KnowledgeModel;
}

const KnowledgesTableUrlCell = ({ item }: SettingsTableUrlCellProps) => {
  const rowItem = get(item, 'url');
  return <OuterLink to={rowItem}>{rowItem}</OuterLink>;
};

export default KnowledgesTableUrlCell;
