import React, { ChangeEventHandler, FC, useCallback, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import { Divider, Tab, Tabs, TextField, Box, List, Drawer, IconButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddCommentIcon from '@mui/icons-material/AddComment';

import { AccessController, Button, ClearFieldAdornment, DeleteDialog, useDialogState } from 'components';
import { CloseIcon, SideBarLink } from '@attrecto/apps-react-components';
import { ConversationModel, RouteConfigurationModel } from 'models';
import { mergeSx } from 'utils/styles';
import { Env } from 'config/env';
import logo from 'assets/images/attrecto_logo.svg';
import { VersionsDisplay } from 'components/versions-display/VersionsDisplay';
import { useDeleteConversation, useInfiniteConversationsList } from 'features/conversations';
import { SortDirection } from 'utils/arrays';
import ChatLink from './ChatLink';
import EditDialog from './EditDialog';
import ShareDialog from './ShareDialog';

const styles = {
  drawer: (theme: Theme) => ({
    width: `${theme.mixins.drawer.width}px`,
    maxWidth: '100%',
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: `${theme.mixins.drawer.width}px`,
      backgroundColor: 'dark.main',
      maxWidth: '100%',
      overflowX: 'hidden',
    },
  }),
  drawerHeader: (theme: Theme) => ({
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'dark.dark',
    padding: theme.spacing(0, 1, 0, 2),
    '& .MuiIconButton-root': {
      color: 'dark.light',
      '&:hover': {
        backgroundColor: 'action.hoverDark',
      },
    },
    '& img': {
      maxHeight: 32,
    },
  }),
  mobileDrawer: {
    display: { md: 'none' },
  },
  desktopDrawer: {
    display: { xs: 'none', md: 'block' },
  },
  drawerOpen: (theme: Theme) => ({
    '&, & .MuiDrawer-paper': {
      width: theme.mixins.drawer.width,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
  drawerClose: (theme: Theme) => ({
    '&, & .MuiDrawer-paper': {
      width: theme.mixins.drawer.width,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  }),
  menuContainer: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  logo: {
    height: 32,
  },
  newChat: {
    mt: 1,
    mb: 1,
    pr: 1,
    '& .MuiButtonBase-root': {
      color: 'common.white',
    },
  },
  divider: {
    borderColor: 'grey.500',
    mb: 1,
  },
  chatList: {
    height: '100%',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: 8,
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'grey.600',
      borderRadius: 2,
    },
  },
  searchBar: {
    p: 1,
    '& .MuiInputBase-root': {
      borderRadius: 1,
      backgroundColor: 'common.white',
    },
  },
  searchBarContainer: {
    display: 'flex',
  },
  datePickerButton: {
    mr: 1,
    mt: 1,
    mb: 1,
    color: 'primary.main',
  },
  tabsContainer: {
    width: '100%',
    mb: 1,
  },
  tabs: {
    width: '100%',
  },
  tab: {
    width: '50%',
    color: 'white',
  },
  hide: {
    display: 'none',
  },
};

enum Types {
  OWN = 'owned',
  SHARED = 'shared',
}

interface SideBarProps {
  open: boolean;
  mobileOpen: boolean;
  toggleSidebar: () => void;
  toggleMobileSidebar: () => void;
  pages: RouteConfigurationModel[];
}

export const getLargeScreen = (theme: Theme) => theme.breakpoints.up(theme.breakpoints.values.md);

export const SideBar: FC<SideBarProps> = ({
  pages = [],
  open = true,
  mobileOpen,
  toggleSidebar,
  toggleMobileSidebar,
}) => {
  const { t } = useTranslation();
  const { chatId } = useParams();
  const largeScreen = useMediaQuery(getLargeScreen);
  const navigate = useNavigate();
  const delay = Env.SEARCH_DELAY;

  const [search, setSearch] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [tab, setTab] = useState<Types>(Types.OWN);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: Types) => {
    setTab(newValue);
  };

  const {
    data: conversations,
    fetchNextPage,
    isLoading,
  } = useInfiniteConversationsList({
    sortBy: 'updatedAt',
    orderBy: SortDirection.DESC,
    search,
    type: Types.OWN,
    pageSize: Env.ROWS_PER_PAGE_OPTIONS[1],
  });

  const {
    data: conversationsShared,
    fetchNextPage: fetchNextPageShared,
    isLoading: isLoadingShared,
  } = useInfiniteConversationsList({
    sortBy: 'updatedAt',
    orderBy: SortDirection.DESC,
    search,
    type: Types.SHARED,
    pageSize: Env.ROWS_PER_PAGE_OPTIONS[1],
  });

  const {
    isOpen: isEditorOpen,
    openDialog: openEditor,
    closeDialog: closeEditor,
    selected: selectedEdit,
  } = useDialogState<ConversationModel>();

  const {
    isOpen: isShareOpen,
    openDialog: openShare,
    closeDialog: closeShare,
    selected: selectedShare,
  } = useDialogState<ConversationModel>();

  const {
    isOpen: isDeleteOpen,
    openDialog: openDelete,
    closeDialog: closeDelete,
    selected: selectedDelete,
  } = useDialogState<ConversationModel>();

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!isLoading && e.currentTarget.scrollTop + e.currentTarget.offsetHeight >= e.currentTarget.scrollHeight - 5) {
      fetchNextPage();
    }
  };

  const handleScrollShared = (e: React.UIEvent<HTMLDivElement>) => {
    if (
      !isLoadingShared &&
      e.currentTarget.scrollTop + e.currentTarget.offsetHeight >= e.currentTarget.scrollHeight - 5
    ) {
      fetchNextPageShared();
    }
  };

  const { mutate: deleteConversation } = useDeleteConversation();

  const navigateNewChat = () => {
    navigate('chat');
  };

  const onMenuIconClick = useCallback(() => {
    return largeScreen ? toggleSidebar() : toggleMobileSidebar();
  }, [largeScreen, toggleSidebar, toggleMobileSidebar]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchBarSearch = useCallback(
    debounce((newValue: string) => setSearch(newValue), delay),
    [delay]
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setValue(e.target.value);
      onSearchBarSearch(e.target.value);
    },
    [setValue, onSearchBarSearch]
  );

  const onClear = useCallback(() => {
    setValue('');
    onSearchBarSearch('');
  }, [setValue, onSearchBarSearch]);

  const onDelete = async () => {
    if (selectedDelete) {
      if (chatId === selectedDelete.id) {
        navigate('chat');
      }
      deleteConversation(selectedDelete.id);
    }
  };

  const content = (
    <>
      <Box sx={styles.drawerHeader}>
        <NavLink end to={'/'}>
          <Box sx={styles.logo}>
            <img src={logo} alt="logo" style={{ height: 'inherit' }} />
          </Box>
        </NavLink>
        <IconButton data-testid="sidebar-toggle-button" onClick={onMenuIconClick} size="large">
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <Box sx={styles.searchBarContainer}>
        <TextField
          sx={styles.searchBar}
          onChange={handleChange}
          value={value}
          fullWidth
          InputProps={{
            endAdornment: <ClearFieldAdornment onClick={onClear} value={value} showSearchIcon />,
          }}
        />
        <Button sx={styles.newChat} onClick={navigateNewChat} variant="outlined">
          <AddCommentIcon />
        </Button>
      </Box>

      <Box sx={styles.tabsContainer}>
        <Tabs value={tab} onChange={handleChangeTab} sx={styles.tabs}>
          <Tab sx={styles.tab} value={Types.OWN} label={t('CHAT.OWN')}></Tab>
          <Tab sx={styles.tab} value={Types.SHARED} label={t('CHAT.SHARED')} />
        </Tabs>
      </Box>

      <Box sx={mergeSx(styles.chatList, tab === Types.OWN ? null : styles.hide)} onScroll={handleScroll}>
        <List sx={styles.menuContainer}>
          {conversations?.pages
            .reduce((acc, cur) => acc.concat(cur.data), [] as ConversationModel[])
            .map((conversation) => (
              <ChatLink
                conversation={conversation}
                key={conversation.id}
                showDelete={openDelete}
                showShare={openShare}
                showEdit={openEditor}
              />
            ))}
        </List>
      </Box>

      <Box sx={mergeSx(styles.chatList, tab === Types.SHARED ? null : styles.hide)} onScroll={handleScrollShared}>
        <List sx={styles.menuContainer}>
          {conversationsShared?.pages
            .reduce((acc, cur) => acc.concat(cur.data), [] as ConversationModel[])
            .map((conversation) => (
              <ChatLink conversation={conversation} key={conversation.id} />
            ))}
        </List>
      </Box>

      <Divider sx={styles.divider} />

      <List sx={styles.menuContainer}>
        {pages.map((page) =>
          !page.noDisplay ? (
            <AccessController key={page.link} allowedFor={page.allowedFor}>
              <SideBarLink icon={page.icon} link={page.link} title={t(`${page.title}`)} key={page.link} />
            </AccessController>
          ) : null
        )}
      </List>
      <VersionsDisplay />
    </>
  );

  return (
    <>
      <DeleteDialog
        open={isDeleteOpen}
        onClose={closeDelete}
        selected={selectedDelete}
        deleteTitleAccessor={'title'}
        onSubmit={onDelete}
      />
      <EditDialog open={isEditorOpen} onClose={closeEditor} selected={selectedEdit} />
      <ShareDialog open={isShareOpen} onClose={closeShare} selected={selectedShare} />
      <Drawer
        data-testid="sidebar"
        variant="persistent"
        anchor="left"
        sx={mergeSx(styles.drawer, styles.desktopDrawer, open ? styles.drawerOpen : styles.drawerClose)}
        open={open}
        onClose={toggleSidebar}
      >
        {content}
      </Drawer>
      <Drawer
        data-testid="mobile-sidebar"
        variant="temporary"
        sx={mergeSx(styles.drawer, styles.mobileDrawer)}
        open={mobileOpen}
        onClose={toggleMobileSidebar}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {content}
      </Drawer>
    </>
  );
};
