import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, Location } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { QueryParamModel } from 'models';
import { selectFilterParams, setFilterParams } from 'store/common';
import { useAppDispatch } from 'utils/useAppDispatch';
import { removeFalsy, removeProp } from 'utils/objects';
import { SortDirection } from 'utils/arrays';
import * as qs from 'utils/query';
import { Env } from 'config/env';

export interface SearchConditions {
  name: string;
  value: string | string[];
}

export type OnSortFunction = (
  event: React.MouseEvent<unknown>,
  sortBy: string,
  orderBy: SortDirection
) => QueryParamModel;

export type OnSearchFunction = (searchConditions: SearchConditions) => QueryParamModel;

const initialParams: Partial<QueryParamModel> = { orderBy: SortDirection.ASC, sortBy: 'name' };

export function getTransformedParams(
  defaultParams: Partial<QueryParamModel>,
  { page = 1, sort, order, pageSize, ...rest }: any = {}
) {
  return removeFalsy({
    page: Number.parseInt(page, 10),
    sort: typeof sort === 'string' ? sort : defaultParams.sort,
    order: typeof order === 'undefined' ? defaultParams.order : (order as SortDirection),
    pageSize:
      typeof pageSize === 'string'
        ? Number.parseInt(pageSize, 10)
        : pageSize || defaultParams.pageSize || Env.ROWS_PER_PAGE_OPTIONS[0],
    ...rest,
  }) as QueryParamModel;
}

export function getInitialParams(
  defaultParams: Partial<QueryParamModel>,
  filterParams: Partial<QueryParamModel>,
  location: Location
) {
  const queryParams = { ...qs.parse(location.search) };

  if (!isEmpty(queryParams)) {
    return getTransformedParams(defaultParams, queryParams);
  }

  if (!isEmpty(filterParams) && filterParams.from === location.pathname) {
    return getTransformedParams(defaultParams, filterParams);
  }

  return getTransformedParams(defaultParams, defaultParams);
}

export const useTableQueryParams = (overrideParams: Partial<QueryParamModel> = initialParams) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const filterParams = useSelector(selectFilterParams);
  const defaultParams = useMemo(() => ({ ...initialParams, ...overrideParams }), [overrideParams]);
  const [params, setParams] = useState<QueryParamModel>(getInitialParams(defaultParams, filterParams, location));

  const addQueryToHistory = useCallback(
    (queryParams: Partial<QueryParamModel> = params) => {
      navigate(`${location.pathname}?${qs.stringify(queryParams)}`, { replace: true });
    },
    [navigate, location.pathname, params]
  );

  const setCurrentParams = useCallback(
    (queryParams?: Partial<QueryParamModel>) => {
      const currentParams = getTransformedParams(defaultParams, queryParams);

      setParams(currentParams);
      dispatch(setFilterParams({ ...currentParams, from: location.pathname }));
      addQueryToHistory(currentParams);

      return currentParams;
    },
    [addQueryToHistory, dispatch, location.pathname, defaultParams]
  );

  const onSort: OnSortFunction = useCallback(
    (event, sortBy, orderBy) => {
      return setCurrentParams({ ...params, sortBy, orderBy });
    },
    [setCurrentParams, params]
  );

  const onSearch: OnSearchFunction = useCallback(
    ({ name, value }) => {
      const currentParams = value ? { ...params, [name]: value || null } : removeProp(params, name);

      return setCurrentParams({ ...currentParams });
    },
    [setCurrentParams, params]
  );

  const onPageChange = useCallback(
    (event: unknown, page: number) => {
      return setCurrentParams({ ...params, page: page + 1 });
    },
    [setCurrentParams, params]
  );

  const onRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      return setCurrentParams({ ...params, page: 1, pageSize: Number.parseInt(event.target.value, 10) });
    },
    [setCurrentParams, params]
  );

  const onFilter = useCallback(
    (filter?: Partial<QueryParamModel> | null) => {
      return setCurrentParams({ ...params, ...(filter || {}), page: 1 });
    },
    [setCurrentParams, params]
  );

  return {
    onPageChange,
    onRowsPerPageChange,
    onSearch,
    onSort,
    onFilter,
    params,
  };
};
