import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { DiscourseModel, DiscourseRole, FeedbackModel } from 'models';
import Question from './Question';
import Answer from './Answer';

interface DiscourseProps {
  messages: DiscourseModel[];
  id?: string;
  childrenIndex?: number;
  activeIndex?: number;
  regenerate: (discourseId: string) => void;
  next: () => void;
  previous: () => void;
  length?: number;
  editQuestion: (discourseId: string | null, text: string) => void;
  submitFeedback: (discourseId: string, feedback: FeedbackModel) => void;
  disabled?: boolean;
}

const Discourse: FC<DiscourseProps> = ({
  messages,
  id,
  childrenIndex = 0,
  regenerate,
  next,
  previous,
  activeIndex,
  length,
  editQuestion,
  submitFeedback,
  disabled,
}) => {
  const [index, setIndex] = useState<number>(childrenIndex);
  const message = useMemo(() => messages.find((item) => id === item.id), [id, messages]);

  const childrenLength = useMemo(() => message?.children.length, [message?.children.length]);

  const nextChild = useCallback(() => {
    setIndex((prev) => (childrenLength && childrenLength > prev + 1 ? prev + 1 : prev));
  }, [childrenLength]);

  const previousChild = useCallback(() => {
    setIndex((prev) => (prev - 1 >= 0 ? prev - 1 : prev));
  }, []);

  const generate = useCallback(() => {
    if (message && message.parentId) {
      regenerate(message.parentId);
    }
  }, [message, regenerate]);

  useEffect(() => {
    if (childrenLength) {
      setIndex(childrenLength - 1);
    }
  }, [childrenLength]);

  useEffect(() => {
    setIndex(0);
  }, [message?.id]);

  const displayMessage = useMemo(() => {
    if (message?.role === DiscourseRole.USER) {
      return (
        <Question
          message={message}
          editQuestion={editQuestion}
          previous={previous}
          next={next}
          active={activeIndex}
          length={length}
          disabled={disabled}
        />
      );
    }

    if (message?.role === DiscourseRole.ASSISTANT) {
      return (
        <Answer
          message={message}
          previous={previous}
          next={next}
          active={activeIndex}
          length={length}
          regenerate={generate}
          submitFeedback={submitFeedback}
          disabled={disabled}
        />
      );
    }

    return <></>;
  }, [activeIndex, disabled, editQuestion, generate, length, message, next, previous, submitFeedback]);

  return (
    <>
      {message && (
        <>
          {displayMessage}
          <Discourse
            messages={messages}
            id={message?.children[index]}
            regenerate={regenerate}
            next={nextChild}
            previous={previousChild}
            activeIndex={index}
            length={childrenLength}
            editQuestion={editQuestion}
            submitFeedback={submitFeedback}
            disabled={disabled}
          />
        </>
      )}
    </>
  );
};

export default Discourse;
