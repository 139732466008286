import React, { FC, useMemo } from 'react';

import { SxProps, Theme } from '@mui/material/styles';
import { styled } from '@mui/material';

import { BaseSeoImageProps, ImgSrcSetLoader } from 'models';
import { defaultImgSrcLoader, getDimensionFromRatio, getSizes, getSrcSet } from 'utils/image';
import { Env } from 'config/env';
import { mergeSx } from 'utils/styles';

const styles = {
  responsive: {
    width: '100%',
    height: 'auto',
  },
};

export const getSeoImageStyles = ({ responsive, sx }: { responsive?: boolean; sx?: SxProps<Theme> }) =>
  mergeSx(responsive ? styles.responsive : undefined, sx);

const Img = styled('img')('');

type srcSetOrLoader =
  | {
      /**
       * Use it to explicitly set the image's srcSet, for most use cases the ```loader``` prop is recommended.
       *
       * When using width descriptor the best candidate is picked from the srcSet based
       * on the actual width needed to render that image on that particular display at runtime.
       * Note: the device pixel ratio (DPR) is also taken into account when calculating the required width:
       * Example: 300px wide screen with DPR 2 will need a 300 x 2 = 600px wide image.
       *
       * If ```srcSet``` is explicitly defined the ```loader``` prop is unavailable
       * as both target the same property.
       */
      srcSet?: string;
      loader?: never;
      imageSizes?: never;
    }
  | {
      srcSet?: never;
      /**
       * Use it to automate the srcset creation using the width descriptor method,
       * ```src``` is the base src of the image,
       * ```width``` is the image's intrinsic width in pixels,
       * ```quality``` is the quality of the image.
       * The loader uses the widths in ```imageSizes``` to create an srcSet entry
       * for each one using the ```src``` and ```quality``` props.
       *
       * The best candidate is picked from the srcSet based
       * on the actual width needed to render that image on that particular display at runtime.
       * Note: the device pixel ratio (DPR) is also taken into account when calculating the required width:
       * Example: 300px wide screen with DPR 2 will need a 300 x 2 = 600px wide image.
       *
       * If ```srcSet``` is explicitly defined the ```loader``` prop is unavailable
       * as both target the same property.
       */
      loader?: ImgSrcSetLoader;
      /**
       * Defines the widths which the ```loader``` uses to generate srcSet entries.
       * If ```srcSet``` is explicitly defined the ```imageSizes``` prop is unavailable,
       * as this value is only used in conjuction with ```loader```.
       */
      imageSizes?: number[];
    };

type ExtendedImageProps = BaseSeoImageProps &
  srcSetOrLoader & {
    /**
     * Defines whether the loader should be used or not.
     */
    noLoader?: boolean;
  };

export const SeoImage: FC<ExtendedImageProps> = ({
  src,
  srcSet,
  loader = defaultImgSrcLoader,
  imageSizes = Env.DEFAULT_IMG_SIZES,
  alt,
  sx,
  sizes,
  responsive = true,
  width,
  height,
  ratio,
  quality = Env.DEFAULT_IMG_QUALITY,
  noLoader,
  ...props
}) => {
  const dimension = ratio ? getDimensionFromRatio(ratio) : { width, height };

  const currentSrcSet = useMemo(
    () => getSrcSet({ src, srcSet, imageSizes, loader, quality, noLoader }),
    [src, srcSet, imageSizes, loader, quality, noLoader]
  );

  const currentSizes = useMemo(() => getSizes(sizes), [sizes]);

  return (
    <Img
      sx={getSeoImageStyles({ responsive, sx })}
      src={src}
      alt={alt}
      data-testid="image"
      srcSet={currentSrcSet}
      sizes={currentSizes}
      height={dimension.height}
      width={dimension.width}
      {...props}
    />
  );
};
