import React, { ReactNode } from 'react';

import Avatar, { AvatarProps } from '@mui/material/Avatar';

import { Image } from 'components';
import { mergeSx } from 'utils/styles';

const styles = {
  small: {
    width: 50,
    height: 50,
  },
  medium: {
    width: 150,
    height: 150,
    fontSize: 70,
  },
  large: {
    width: 250,
    height: 250,
    fontSize: 130,
  },
};

export enum ProfileImageSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface ProfileImageProps extends Omit<AvatarProps, 'sizes' | 'imgProps' | 'srcSet'> {
  noImage?: ReactNode;
  size?: ProfileImageSize;
}

export const ProfileImage = ({ src, noImage, size = ProfileImageSize.SMALL, sx, alt, ...props }: ProfileImageProps) => {
  return (
    <Avatar sx={mergeSx(styles[size], sx)} {...props}>
      {src ? <Image src={src} alt={alt} /> : noImage}
    </Avatar>
  );
};
