import React, { useMemo } from 'react';

import { BaseSeoImage, ImgSrcSetLoader } from 'models';
import { defaultImgSrcLoader as defImgSrcLoader } from 'utils/image';
import { SeoImage } from 'components';
import theme from 'theme';
import { Env } from 'config/env';
import { configureSourcesConfig } from './pictureUtil';
import {
  getBaseImageProps,
  getBreakpointSourceConfigs,
  NonResponsiveSources,
  ResponsiveSources,
} from './pictureMapperUtil';

type SourcesProps = ResponsiveSourcesProps | NonResponsiveSourcesProps;

type NonResponsiveSourcesProps = {
  /**
   * Allows the image to be responsive.
   * If ```true``` the image will take on the size of it's container.
   * If ```false``` the image will take on the size specified in width and height props.
   */
  responsive: false;
  sources: NonResponsiveSources;
};

type ResponsiveSourcesProps = {
  /**
   * Allows the image to be responsive.
   * If ```true``` the image will take on the size of it's container.
   * If ```false``` the image will take on the size specified in width and height props.
   */
  responsive?: true;
  /**
   * Defines the default (base) image source and sources on different screen sizes.
   *
   * **Image source configurations - *base*** (required):
   *
   * This configuration will be used if the picture tag is not supported by the browser, or
   * if none of the breakpoints match. As this configuration is only used for the image, source configuration
   * options are unavailable. An object needs to be provided, which includes the following properties:
   *
   * ```width``` (required if ratio is not specified) -  Defines the intrinsic width of the image in pixels.
   * Must be an integer without a unit. Should be added to prevent layout shift.
   *
   * Use either width-height pair OR ratio
   * as both affect the image width and height.
   *
   * ```height``` (required if ratio is not specified) - Defines the intrinsic height of the image in pixels.
   * Must be an integer without a unit. Should be added to prevent layout shift.
   *
   * Use either width-height pair OR ratio
   * as both affect the image width and height.
   *
   * ```ratio``` (required if width-height pair is not specified) - Defines the width-height ratio of the image.
   * Can only be used in responsive mode because only in this mode can the component scale the image up without
   * concrete width and height values.
   * Should be added to prevent layout shift.
   *
   * Use either width-height pair OR ratio
   * as both affect the image width and height.
   *
   * ```sizes``` (optional) - For details see the SeoImage sizes prop. If omitted the default quality value
   * is used.
   *
   * *Only for the "loader" variant:*
   *
   * ```variant="loader"```(required) - This value differentiates between the ```loader``` and ```manual```
   * complex source variants.
   *
   * ```srcForLoader``` (required) - This value is passed to the ```loader``` function as the src argument.
   *
   * ```loader``` (optional) - For details see the SeoImage loader prop. If omitted the default image source loader
   * is used.
   *
   * ```sourceSizes``` (optional) - For details see the SeoImage imageSizes prop. If omitted the default imageSizes
   * is used.
   *
   * ```quality``` (optional) - For details see the SeoImage quality prop. If omitted the default imageSizes
   * is used.
   *
   * *Only for the "manual" variant:*
   *
   * ```variant="manual"``` (required) - This value differentiates between the ```loader``` and ```manual```
   * complex source variants.
   *
   * ```src``` (required) - Used for the underlying img tag, used when none of the source mediaquerys match or
   * the picture tag is not supported by the browser AND ```srcSet``` is not defined.
   *
   * ```srcSet``` (optional) - For manually defining the srcset property.
   *
   * **Image source configurations - *breakpoints*** (optional) - (e.g., xs, sm, md):
   *
   * These configuration entries are defined for breakpoints.
   * Each configuration option uses the min-width media selector, for example: if sm breakpoint is set to 600px
   * then the sm configuration will take effect if the screen size matches the (min-width: 600px) selector.
   * If multiple breakpoint configurations match then the largest breakpoint takes precedence.
   *
   * As these configurations are used for source tags any source tag property can be given when using complex configuration,
   * except media, srcSet and sizes attributes.
   *
   * In case of ```responsive``` picture either ratio OR height-width pair
   * can be added. In case of ```non-responsive``` picture only height-width pair can be provided.
   *
   * *Simple configuration*:
   *
   * Only a string needs to be provided. It will serve as the image source
   * for the given screen.
   *
   * *Complex configuration:*
   *
   * For deep customization, an object needs to be provided, which includes the following properties:
   *
   * ```width``` (optional, unavailable if ratio is specified) -  Defines the intrinsic width of the image in pixels.
   * Must be an integer without a unit. Should be added to prevent layout shift.
   *
   * Use either width-height pair OR ratio
   * as both affect the image width and height.
   *
   * ```height``` (optional, unavailable if ratio is specified) - Defines the intrinsic height of the image in pixels.
   * Must be an integer without a unit. Should be added to prevent layout shift.
   *
   * Use either width-height pair OR ratio
   * as both affect the image width and height.
   *
   * ```ratio``` (optional, unavailable if width-height pair is specified) - Defines the width-height ratio of the image.
   * Can only be used in responsive mode because only in this mode can the component scale the image up without
   * concrete width and height values.
   * Should be added to prevent layout shift.
   *
   * Use either width-height pair OR ratio
   * as both affect the image width and height.
   *
   * ```sizes``` (optional) - For details see the SeoImage sizes prop. If omitted the default quality value
   * is used.
   *
   * *Only for the "loader" variant*:
   *
   * ```variant="loader"``` (required) - This value differentiates between the ```loader``` and ```manual```
   * complex source variants.
   *
   * ```srcForLoader``` (required) - This value is passed to the ```loader``` function as the src argument.
   *
   * ```loader``` (optional) - For details see the SeoImage loader prop. If omitted the default image source loader
   * is used.
   *
   * ```sourceSizes``` (optional) - For details see the SeoImage imageSizes prop. If omitted the default imageSizes
   * is used.
   *
   * ```quality``` (optional) - For details see the SeoImage quality prop. If omitted the default imageSizes
   * is used.
   *
   * *Only for the "manual" variant*:
   *
   * ```variant="manual"``` (required) - This value differentiates between the ```loader``` and ```manual```
   * complex source variants.
   *
   * ```srcSet``` (required) - For manually defining the srcset property. For simpler use cases an img url
   * is sufficient.
   */
  sources: ResponsiveSources;
};

type BasePictureProps = {
  /**
   * Defines the default image source loader for all source configuration entries. The loader defined in the source
   * configuration entries takes precedence. If ```defaultImgSrcLoader``` is omitted the project global default image
   * source loader is used as default.
   */
  defaultImgSrcLoader?: ImgSrcSetLoader;
  /**
   * Defines the default image quality for all source configuration entries. The quality value defined in the source
   * configuration entries takes precedence. If ```defaultQuality``` is omitted the project global default image quality
   * is used as default.
   */
  defaultQuality?: number;
  /**
   * Defines the default sourceSizes for all source configuration entries. The sourceSizes property defined in the source
   * configuration entries takes precedence. If ```defaultImageSizes``` is omitted the project global image sizes value
   * is used as default.
   */
  defaultImageSizes?: number[];
};

export type PictureProps = Omit<BaseSeoImage, 'src' | 'srcSet' | 'sizes' | 'quality' | 'ratio' | 'responsive'> &
  BasePictureProps &
  SourcesProps;

const getSourcesConfig = configureSourcesConfig(theme.breakpoints.values);

export const Picture = ({
  sources,
  responsive = true,
  defaultImgSrcLoader = defImgSrcLoader,
  defaultQuality = Env.DEFAULT_IMG_QUALITY,
  defaultImageSizes = Env.DEFAULT_IMG_SIZES,
  ...props
}: PictureProps) => {
  const sourcesConfig = useMemo(() => {
    const { base: _, ...sourcesWithoutDefault } = sources;
    const sources2 = getBreakpointSourceConfigs({
      sources: sourcesWithoutDefault,
      responsive: !!responsive,
      defaultConfig: {
        defaultImgSrcLoader,
        defaultQuality,
        defaultImageSizes,
      },
    });
    return getSourcesConfig(sources2);
  }, [sources, defaultImgSrcLoader, defaultQuality, defaultImageSizes, responsive]);

  const imgSrcParams = useMemo(
    () => getBaseImageProps({ responsive, imageSrcConfig: sources.base }),
    [sources.base, responsive]
  );

  return (
    <picture data-testid="picture">
      {sourcesConfig.map(({ key, ...rest }) => (
        <source key={key} {...rest} />
      ))}
      <SeoImage {...props} {...imgSrcParams} />
    </picture>
  );
};
