import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { QueryParamModel } from 'models';

export interface SideBarState {
  open: boolean;
  mobileOpen: boolean;
}

export interface LoaderState {
  show: boolean;
}

export interface ApiState {
  version: string;
  env: string;
}

export interface CommonState {
  loader: LoaderState;
  sideBar: SideBarState;
  filterParams: QueryParamModel;
  apiInfo: ApiState;
}

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    loader: {
      show: false,
    },
    notification: null,
    sideBar: {
      open: localStorage.getItem('sideBarOpen') !== 'false',
      mobileOpen: false,
    },
    filterParams: {},
    apiInfo: {
      version: '-',
      env: '-',
    },
  } as CommonState,
  reducers: {
    showLoader: (state) => {
      state.loader.show = true;
    },
    hideLoader: (state) => {
      state.loader.show = false;
    },
    toggleSidebar: (state) => {
      const open = !state.sideBar.open;

      state.sideBar = {
        ...state.sideBar,
        open,
      };

      localStorage.setItem('sideBarOpen', String(open));
    },
    toggleMobileSidebar: (state) => {
      state.sideBar.mobileOpen = !state.sideBar.mobileOpen;
    },
    setFilterParams: (state, action: PayloadAction<QueryParamModel>) => {
      state.filterParams = action.payload;
    },
    setApiInfo: (state, action: PayloadAction<ApiState>) => {
      state.apiInfo.version = action.payload.version;
      state.apiInfo.env = action.payload.env;
    },
  },
});

const { actions } = commonSlice;

export const commonReducer = commonSlice.reducer;

export const { showLoader, hideLoader, toggleSidebar, toggleMobileSidebar, setFilterParams, setApiInfo } = actions;

export const selectSideBarState = (state: ApplicationState) => {
  return state.common.sideBar;
};

export const selectLoaderState = (state: ApplicationState) => {
  return state.common.loader;
};

export const selectFilterParams = (state: ApplicationState) => {
  return state.common.filterParams;
};

export const selectApiInfoState = (state: ApplicationState) => {
  return state.common.apiInfo;
};
