import { DevTool as RHFDevtool } from '@hookform/devtools';

type DevToolProps = typeof RHFDevtool;

export const DevTool: DevToolProps =
  process.env.NODE_ENV !== 'development'
    ? () => {
        return null;
      }
    : RHFDevtool;
