import { Role } from 'config/roles';
import { RolesModel, RouteConfigurationModel } from 'models';

export type AllowedFor = Role[] | Role;

export interface AccessRestriction {
  allowedFor?: AllowedFor;
}

export const hasPermission = (roles?: RolesModel[], allowedFor?: AllowedFor) => {
  if (!allowedFor) {
    return true;
  }

  if (!roles && !Array.isArray(roles)) {
    return false;
  }

  if (Array.isArray(allowedFor)) {
    return roles.some((role) => allowedFor.some((allowedRole) => allowedRole === role.name));
  }

  return roles.some((role) => allowedFor === role.name) || false;
};

export const filterRoutes = (routes: RouteConfigurationModel[], roles?: RolesModel[]) => {
  if (!Array.isArray(routes)) {
    return [];
  }

  return routes.reduce((filtered, current) => {
    if (hasPermission(roles, current.allowedFor)) {
      filtered.push({
        ...current,
        children: current.children ? current.children.filter((route) => hasPermission(roles, route.allowedFor)) : [],
      } as RouteConfigurationModel);
    }

    return filtered;
  }, [] as RouteConfigurationModel[]);
};
