import { ConversationModel, DiscourseListResponse, QueryParamModel } from 'models';
import request, { Methods } from 'utils/request';
import { EntityService } from 'utils/entityService';

interface ConversationsResponse {
  data: ConversationModel[];
  total: number;
}

class ConversationsService extends EntityService {
  constructor() {
    super('conversations');
  }

  async createConversation(data?: Partial<ConversationModel>) {
    return request<ConversationModel>({
      method: Methods.POST,
      resource: `${this.resource}`,
      data,
    });
  }

  async editConversation(data: ConversationModel) {
    return request<ConversationModel>({
      method: Methods.PUT,
      resource: `${this.resource}/${data.id}`,
      data,
    });
  }

  async createConversationTitle(id: string) {
    return request<string>({
      method: Methods.PATCH,
      resource: `${this.resource}/${id}`,
    });
  }

  async deleteConversation(id: string) {
    return request<void>({
      method: Methods.DELETE,
      resource: `${this.resource}/${id}`,
    });
  }

  async getConversations(params?: QueryParamModel | null) {
    return request<ConversationsResponse>({
      resource: `${this.resource}`,
      params,
    });
  }

  async getConversation(id: string) {
    return request<ConversationModel>({
      resource: `${this.resource}/${id}`,
    });
  }

  async getDiscourses(id: string) {
    return request<DiscourseListResponse>({
      resource: `${this.resource}/${id}/discourses`,
    });
  }
}

export const conversationService = new ConversationsService();
