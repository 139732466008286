import { QueryParamModel, KnowledgeModel, KnowledgeQueryModel } from 'models';
import request, { Methods } from 'utils/request';
import { EntityService } from 'utils/entityService';

class KnowledgesService extends EntityService {
  constructor() {
    super('knowledges');
  }

  async createKnowledge(data: FormData) {
    return request<KnowledgeModel>({
      method: Methods.POST,
      resource: `${this.resource}`,
      data,
    });
  }

  async editKnowledge(data: Partial<KnowledgeModel>) {
    return request<KnowledgeModel>({
      method: Methods.PUT,
      resource: `${this.resource}/${data?.id}`,
      data,
    });
  }

  async deleteKnowledge(id: string) {
    return request<void>({
      method: Methods.DELETE,
      resource: `${this.resource}/${id}`,
    });
  }

  async deleteKnowledges() {
    return request<void>({
      method: Methods.DELETE,
      resource: `${this.resource}`,
    });
  }

  async getKnowledges(params?: QueryParamModel | null) {
    return request<KnowledgeQueryModel>({
      resource: `${this.resource}`,
      params,
    });
  }

  async getKnowledge(id: string) {
    return request<KnowledgeModel>({
      resource: `${this.resource}/${id}`,
    });
  }
}

export const knowledgesService = new KnowledgesService();
